<template>
    <div class="w-full bg-TextField px-6 py-4 rounded-xl flex flex-col space-y-3 items-center font-dm-sans text-sm">
        <div class="w-full font-bold flex justify-between items-center pb-3">
            <span>10 Tage Testphase:</span>
            <span>0,00€</span>
        </div>

        <p class="w-full text-left text-sm font-bold text-MittelGrau">Ab dem {{ abonnementBeginn }}:</p>
        <!-- Grundpreis, inklusive UST -->
        <div v-if="laedt === true" class="w-full flex justify-between items-center">
            <div class="w-[60%] h-5 rounded-full bg-LeichtesGrau bg-opacity-30"></div>
            <div class="w-20 h-5 rounded-full bg-LeichtesGrau bg-opacity-30"></div>
        </div>
        <div v-else class="w-full flex justify-between items-center">
            <span>{{ grundPreis.beschreibung }}:</span>
            <span>{{ grundPreis.brutto }}€</span>
        </div>

        <div v-if="preisMinderung != null" class="w-full flex justify-between items-center">
            <span>{{ preisMinderung.beschreibung }}:</span>
            <span>- {{ preisMinderung.brutto }}€</span>
        </div>

        <!-- Spacer -->
        <div class="w-full h-[1px] bg-LeichtesGrau bg-opacity-40 my-3"></div>

        <div v-if="laedt === true" class="w-full flex justify-between items-center">
            <div class="w-32 h-5 rounded-full bg-LeichtesGrau bg-opacity-30"></div>
            <div class="w-20 h-5 rounded-full bg-LeichtesGrau bg-opacity-30"></div>
        </div>
        <div v-else class="w-full flex justify-between items-center font-bold">
            <span>Summe:</span>
            <span>{{ summe }}€</span>
        </div>
    </div>
</template>
<script>
import { mehrwertSteuerSatz } from '@/constants';
import { preisStringGenerieren } from '@/dienste/helfer';
import store from '@/store';
import dayjs from 'dayjs';
import { computed } from 'vue';

export default {
    setup() {

        const laedt = computed(() => store.state.rechnungsPositionenLaden)

        const abonnementBeginn = computed(() => dayjs.unix(store.state.abonnementBeginn).format("DD.MM.YYYY"))

        const grundPreis = computed(() => {
            if (laedt.value === false) {
                const gp = store.state.abonnementRP.find(p => p.art === "grundpreis")
                const brutto = preisStringGenerieren((gp.wert * mehrwertSteuerSatz))
                return {
                    beschreibung: gp.beschreibung,
                    brutto: brutto
                }
            }
        })

        const preisMinderung = computed(() => {
            if (laedt.value === false && store.state.preisMinderung != null) {
                const pm = store.state.abonnementRP.find(p => p.art === "preisminderung")
                if (pm) {
                    return {
                        beschreibung: pm.beschreibung,
                        brutto: preisStringGenerieren(pm.wert * mehrwertSteuerSatz)
                    }
                }
            }
        })

        const summe = computed(() => {
            if (laedt.value === false) {
                const brutto = store.state.abonnementRP.find(p => p.art === "brutto")
                return preisStringGenerieren(brutto.wert)
            }
        })

        return {
            laedt,
            abonnementBeginn,
            grundPreis,
            preisMinderung,
            summe
        }
    }
}
</script>
<template>
  <div>
    <!-- Mobile -->
    <div
      v-if="istMobile === true"
      class="flex justify-start items-center"
      :class="{
        'text-DentaBlue bg-LeichtesBlau': vorraussetzung === true,
        'text-MittelGrau bg-TextField': vorraussetzung === false,
      }"
    >
      <ion-icon class="text-xl mr-3" name="checkmark"></ion-icon>
      <p>{{ text }}</p>
    </div>

    <!-- Desktop -->
    <p
      v-else
      class="px-3 py-1 text-xs rounded-lg flex mr-2 mb-2"
      :class="{
        'text-DentaBlue bg-LeichtesBlau': vorraussetzung === true,
        'text-MittelGrau bg-TextField': vorraussetzung === false,
      }"
    >
      {{ text }}
    </p>
  </div>
</template>

<script>
import { computed } from "@vue/runtime-core";
export default {
  props: ["vorraussetzung", "text"],
  setup() {
    //TODO: Hier basierend auf Prop entscheiden
    let istMobile = computed(() => false);
    return { istMobile };
  },
};
</script>
<template>
    <form @submit="weiter" @keydown.enter.prevent=""
        :class="{ 'onboarding-komponente': mobile === false, 'onboarding-komponente-mobile': mobile === true }">
        <!-- Titel -->
        <Titel v-if="!mobile" titel="Ihre Organisation" untertitel="Geben Sie Name und Anschrift Ihrer Organisation an."
            :hatZurueckButton="true" @zurueck="zurueck" />
        <ZurueckButton v-else @click="zurueck" />


        <!-- Formular -->
        <div class="w-full flex flex-col space-y-2" :class="{ 'h-full': mobile === true }">

            <input type="text" v-model="praxisName" class="textfeld-adresse w-full mb-4" placeholder="Organisationsname"
                autocomplete="organization">

            <div class="w-full flex justify-between items-center space-x-2">
                <input type="text" v-model="strasse" placeholder="Straße" class="textfeld-adresse w-3/4"
                    autocomplete="address-line1">
                <input type="text" v-model="hausnummer" placeholder="Haus" class="textfeld-adresse w-1/4"
                    autocomplete="address-line2">
            </div>
            <input type="text" v-model="zusatz1" placeholder="Adresszusatz 1" class="textfeld-adresse"
                autocomplete="address-line3">
            <input type="text" v-model="zusatz2" placeholder="Adresszusatz 2" class="textfeld-adresse">
            <div class="w-full flex justify-between items-center space-x-2 ">
                <input type="text" v-model="postleitzahl" placeholder="Plz" class="textfeld-adresse w-1/3"
                    autocomplete="postal-code">
                <input type="text" v-model="stadt" placeholder="Stadt" class="textfeld-adresse w-2/3"
                    autocomplete="city">
            </div>
            <div class="w-full rounded-md bg-TextField h-12 relative">
                <select class="absolute w-full h-full focus:outline-none opacity-0" v-model="land">
                    <option v-for="l in laender" :key="l" :value="l">{{ l }}</option>
                </select>
                <p class="absolute z-10 w-full h-full px-5 py-3 flex justify-start items-center pointer-events-none">{{
                    land
                    }}</p>
            </div>
        </div>

        <!-- "Weiter" Button -->
        <WeiterButton :mobile="mobile" :deaktiviert="!adresseGueltig || !nameGueltig" @klick="weiter" />
    </form>
</template>
<script>
import Titel from '@/components/Titel.vue';
import WeiterButton from '@/components/WeiterButton.vue';
import ZurueckButton from '@/components/ZurueckButton.vue';
import { computed, onMounted, ref, watch } from 'vue';
import { laender } from '@/constants';
import { testAdressenBestandteil, testNurZiffern } from '@/constants/regex';
import store from '@/store';
export default {
    components: {
        Titel,
        WeiterButton,
        ZurueckButton
    },
    props: ["mobile"],
    emits: ["weiter", "zurueck"],
    setup(props, context) {
        const weiter = () => context.emit("weiter")
        const zurueck = () => context.emit("zurueck")

        const istLabor = computed(() => store.state.istViewer)

        const praxisName = ref("")
        const strasse = ref("")
        const hausnummer = ref("")
        const zusatz1 = ref("")
        const zusatz2 = ref("")
        const postleitzahl = ref("")
        const stadt = ref("")
        const land = ref(laender[0])

        const adresse = computed(() => {
            return {
                name: praxisName.value,
                strasse: strasse.value,
                hausnummer: hausnummer.value,
                zusatz1: zusatz1.value,
                zusatz2: zusatz2.value,
                postleitzahl: postleitzahl.value,
                stadt: stadt.value,
                land: land.value
            }
        })

        const nameGueltig = computed(() => praxisName.value != "")

        const adresseGueltig = computed(() =>
            nameGueltig.value &&
            testAdressenBestandteil(strasse.value) &&
            testAdressenBestandteil(hausnummer.value) &&
            testAdressenBestandteil(postleitzahl.value) &&
            testAdressenBestandteil(stadt.value) &&
            testAdressenBestandteil(land.value))

        watch(adresse, val => store.commit("setAdresse", val))

        //Falls bereits eine Adresse angegeben wurde, werden die Eingabefelder hier entsprechend gesetzt
        onMounted(() => {
            if (store.state.adresse != null) {
                praxisName.value = store.state.adresse.name
                strasse.value = store.state.adresse.strasse
                hausnummer.value = store.state.adresse.hausnummer
                zusatz1.value = store.state.adresse.zusatz1
                zusatz2.value = store.state.adresse.zusatz2
                postleitzahl.value = store.state.adresse.postleitzahl
                stadt.value = store.state.adresse.stadt
                land.value = store.state.adresse.land
            }
        })

        return {
            laender,
            praxisName,
            strasse,
            hausnummer,
            zusatz1,
            zusatz2,
            postleitzahl,
            stadt,
            land,
            nameGueltig,
            adresseGueltig,
            istLabor,
            weiter,
            zurueck
        }
    }
}
</script>
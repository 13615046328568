<template>
    <div v-if="laedt" class="w-full h-full flex justify-center items-center">
        <LottieAnimation :animation-data="ladenBlau" :loop="true" class="w-40" />
    </div>
    <div v-else class="w-full h-full bg-white flex" :class="{
        'flex-col justify-start items-start pt-4 px-4 overflow-y-auto': mobile === true,
        'flex-row justify-start items-center p-5': mobile === false
    }">
        <FortschrittsAnzeige :mobile="mobile" :index="index" :schritte="schritte" :fehler="fehler"
            class="w-1/3 sm:mr-36" :class="{ 'h-full': !mobile }" />
        <!-- TODO: Transition Animation einfügen -->
        <component :is="komponente" :mobile="mobile" @zurueck="zurueckEmpfangen" @weiter="weiterEmpfangen"
            @testDatenSetzen="testDatenSetzen"></component>
    </div>
</template>
<script>
import ladenBlau from "@/assets/animations/laden-blau.json"
import { computed, onMounted, ref, watch, watchEffect } from 'vue';
import { praxisSchritte, laborSchritte } from '@/constants';
import { zahlungsMethoden } from '@/constants';
import { testRegistrierungsDaten } from "@/test-data";
import { useRoute } from 'vue-router'
import { LottieAnimation } from 'lottie-web-vue';
import FortschrittsAnzeige from "@/components/FortschrittsAnzeige/FortschrittsAnzeige.vue"
import Agb from '@/views/agb/Agb.vue';
import EigentuemerInformationen from '@/views/eigentuemer-informationen/EigentuemerInformationen.vue';
import Praxis from '@/views/praxis/Praxis.vue';
import Avv from "@/views/avv/Avv.vue"
import ZahlungsInformationen from "@/views/zahlungs-informationen/ZahlungsInformationen.vue"
import Zusammenfassung from "@/views/zusammenfassung/Zusammenfassung.vue"
import NaechsteSchritte from "@/views/naechste-schritte/NaechsteSchritte.vue";
import { paketeAbfragen } from '@/dienste/firestore';
import store from '@/store';
import router from "@/router";
export default {
    components: {
        LottieAnimation,
        FortschrittsAnzeige,
        Agb,
        EigentuemerInformationen,
        Praxis,
        Avv,
        ZahlungsInformationen,
        Zusammenfassung,
        NaechsteSchritte
    },
    props: ["mobile"],
    setup(props, context) {
        const route = useRoute()

        const laedt = computed(() => store.state.laedt)

        const aktuellesPaket = ref(store.state.paket)
        const aktuelleFrist = ref(store.state.frist)
        const aktuellePreisminderung = ref(store.state.preisMinderung)
        const aktuelleZahlungsMethode = ref(store.state.zahlungsMethode)

        watchEffect(() => {
            if (laedt.value === false && store.state.paket != null && (aktuellesPaket.value == null || store.state.paket.id !== aktuellesPaket.value.id)) {
                aktuellesPaket.value = store.state.paket
                store.commit("setRechungsPositionen")
            }
        })

        watchEffect(() => {
            if (laedt.value === false && typeof store.state.frist === "string" && aktuelleFrist.value !== store.state.frist) {
                aktuelleFrist.value = store.state.frist
                store.commit("setRechungsPositionen")
            }
        })

        watchEffect(() => {
            if (laedt.value === false && store.state.preisMinderung !== aktuellePreisminderung.value) {
                aktuellePreisminderung.value = store.state.preisMinderung
                store.commit("setRechungsPositionen")
            }
        })

        watchEffect(() => {
            if (laedt.value === false && store.state.zahlungsMethode !== aktuelleZahlungsMethode.value) {
                aktuelleZahlungsMethode.value = store.state.zahlungsMethode
                store.commit("setRechungsPositionen")
            }
        })

        //ID der Signature Request, nachdem der AVV unterzeichnet wurde
        const signatureRequestId = ref(null)

        //Fomularfelder Zahlungsinformationen
        const frist = ref(null)

        watch(frist, nf => console.log(nf))

        const preisMinderung = ref(null)
        const zahlungsMethode = ref(zahlungsMethoden[0])
        const praxisId = ref(null)

        const praxisIdAktualisiert = (data) => praxisId.value = data

        /** Index der aktuell angezeigten Komponente */
        const index = ref(0)

        /** Vom Nutzer zuvor gewähltes Paket. */
        const paket = ref(null)

        /** Vollständige URL zur Mollie-Zahlung.*/
        const zahlungsLink = ref(null)

        const schritte = computed(() => store.state.istViewer ? laborSchritte : praxisSchritte)

        const komponente = computed(() => {
            if (store.state.istViewer) {
                //Komponenten für Viewer (Kostenlose) Abonnenten
                switch (index.value) {
                    case 1: return EigentuemerInformationen;
                    case 2: return Praxis
                    case 3: return Avv
                    case 4: return Zusammenfassung
                    case 5: return NaechsteSchritte
                    default:
                        return Agb
                }
            } else {
                //Komponenten für Reguläre Abonnenten
                switch (index.value) {
                    case 1: return EigentuemerInformationen;
                    case 2: return Praxis
                    case 3: return Avv
                    case 4: return ZahlungsInformationen
                    case 5: return Zusammenfassung
                    default:
                        return Agb
                }
            }
        })

        /**
         * Setzt das Paket und den Zeitraum anhand der empfangenen parameter
         */
        async function initialisieren() {
            //Globaler Ladezustand wird gesetzt
            store.commit("setLaedt", true)

            //Zunächst wird geprüft, ob der Nutzer eine Praxis oder ein Labor einrichten möchte.
            if (route.query.t === "viewer") {
                store.state.istViewer = true
            } else {
                //Standardfall ist Praxis
                store.state.istViewer = false

                const pakete = store.state.pakete
                if (pakete == null) {
                    //Pakete werden abgefragt, falls nicht bereits geschehen
                    store.commit("setPakete", await paketeAbfragen())
                    return await initialisieren()
                }

                //Falls die Route ein gewünschtes, gültiges Paket enthält wird es hier gesetzt
                if (typeof route.query.p === "string" && pakete.some(p => p.id === route.query.p)) {
                    const paket = pakete.find(p => p.id === route.query.p)
                    store.commit("setPaket", paket)
                } else {
                    //Standardpaket ist "DentaSnap Pro"
                    const essential = pakete.find(p => p.name === "Essentials")
                    store.commit("setPaket", essential)
                }

                //Falls die Route eine gewünschte Frist enthält, wird sie hier gesetzt
                var frist
                if (route.query.z === "m" || route.query.z === "j") {
                    frist = route.query.z
                } else {
                    frist = "j"
                }
                store.commit("setFrist", frist)

                //Standardmäßig wird "Kreditkarte" als Zahlungsmethode gesetzt
                store.commit("setZahlungsMethode", "creditcard")

                //Im Hintergrund werden die Kosten für die initiale Konfiguration geladen
                store.commit("setRechungsPositionen")
            }
            store.commit("setLaedt", false)
        }

        function weiterEmpfangen() {
            if (index.value < schritte.value.length - 1) {
                index.value++
            }
        }

        function zurueckEmpfangen() {
            if (index.value > 0) {
                index.value--
            }
        }

        function testDatenSetzen() {
            store.commit("setTestDaten")
            //Navigiert zur "Abonnement" Seite
            index.value = 4
        }

        /** Ob aktuell ein Fehlerscreen auf dem UI angezeigt wird (für FortschrittsAnzeige!) */
        const fehler = ref(false)

        onMounted(initialisieren)

        return {
            ladenBlau,
            laedt,
            schritte,
            index,
            fehler,
            paket,
            praxisId,
            signatureRequestId,
            frist,
            preisMinderung,
            zahlungsMethode,
            zahlungsLink,
            komponente,
            praxisIdAktualisiert,
            weiterEmpfangen,
            zurueckEmpfangen,
            testDatenSetzen
        }
    }
}
</script>
<template>
    <div class="w-full px-10 py-6 rounded-2xl bg-TextField flex flex-col space-y-3 items-center">
        <p class="w-full text-sm text-MittelGrau">Rechnungsadresse</p>
        <span class="w-full">{{ ra.name }}<br />{{ ra.strasse }} {{ ra.hausnummer }}<br />{{ ra.postleitzahl }} {{ ra.stadt
        }}<br />{{ ra.land }}</span>
    </div>
</template>
<script>
import store from '@/store';
import { computed } from 'vue';

export default {
    setup() {
        const ra = computed(() => store.state.adresse)
        return {
            ra
        }
    }
}
</script>
<template>
    <button v-if="deaktiviert === false"
        class="w-full flex justify-between items-center px-3 py-4 rounded-lg font-dm-sans text-lg" @click="aktualisiert">
        <div class="w-full flex justify-start items-center space-x-3">
            <Checkbox :wert="ausgewaehlt" />
            <p class="text-xl" :class="{ 'text-DentaBlue': ausgewaehlt, 'text-black': !ausgewaehlt }">{{
                name }}</p>
        </div>
        <img :src="logo" class="h-5">
    </button>
    <div v-else class="w-full" v-tooltip="`${name} ist aus technischen Gründen nicht verfügbar.`">
        <button
            class="w-full flex justify-between items-center px-3 py-4 rounded-lg font-dm-sans text-lg opacity-20 pointer-events-none"
            :deaktiviert="true">
            <div class="w-full flex justify-start items-center space-x-3">
                <Checkbox :wert="ausgewaehlt" />
                <p class="text-xl text-black">{{
                    name }}</p>
            </div>
            <img :src="logo" class="h-5">
        </button>
    </div>
</template>
<script>
import { computed, watchEffect } from 'vue'
import Checkbox from '@/components/Checkbox/Checkbox.vue'
import store from '@/store'
export default {
    components: { Checkbox },
    props: ["mobile", "methode"],
    setup(props, context) {
        const aktuell = computed(() => store.state.zahlungsMethode)
        const ausgewaehlt = computed(() => aktuell.value === props.methode)
        const deaktiviert = computed(() => props.methode === "giropay" && store.state.frist === "j")

        /** Falls der Nutzer die Methode auswählt, sie aber deaktiviert ist, wird die Auswahl rückgängig gemacht.  */
        watchEffect(() => {
            if (ausgewaehlt.value === true && deaktiviert.value === true) {
                store.commit("setZahlungsMethode", null)
            }
        })

        const name = computed(() => {
            switch (props.methode) {
                case "creditcard": return "Kreditkarte"
                case "giropay": return "GiroPay"
                case "paypal": return "PayPal"
                default: return ""
            }
        })

        const logo = computed(() => {
            switch (props.methode) {
                case "creditcard": return require("@/assets/pm-logos/kreditkarten.png")
                case "giropay": return require("@/assets/pm-logos/giropay.png")
                case "paypal": return require("@/assets/pm-logos/paypal.png")
                default: return ""
            }
        })

        function aktualisiert() {
            if (deaktiviert.value === false) {
                store.commit("setZahlungsMethode", props.methode)
            }
        }

        return {
            deaktiviert,
            ausgewaehlt,
            name,
            logo,
            aktualisiert
        }
    }
}
</script>
import { createStore } from "vuex";
import { testRegistrierungsDaten } from "@/test-data";
import { funktionAufrufen } from "@/dienste/functions";

// Create a new store instance.
const store = createStore({
  state() {
    return {
      laedt: false,
      istViewer: false,
      pakete: null,
      paket: null,
      frist: null,
      agb: false,
      angebote: false,
      displayName: null,
      emailAdresse: null,
      passwort: null,
      adresse: null,
      signatureRequestId: null,
      zahlungsMethode: null,
      preisMinderung: null,
      rechnungsPositionenLaden: false,
      testphaseRP: null,
      abonnementRP: null,
      abonnementBeginn: null,
      zahlungsUrl: null,
      anmeldeToken: null,
    };
  },
  mutations: {
    setLaedt: (state, wert) => (state.laedt = wert),
    setPakete: (state, wert) => (state.pakete = wert),
    setPaket: (state, wert) => (state.paket = wert),
    setFrist: (state, wert) => (state.frist = wert),
    setAgb: (state, wert) => (state.agb = wert),
    setAngebote: (state, wert) => (state.angebote = wert),
    setDisplayName: (state, wert) => (state.displayName = wert),
    setEmailAdresse: (state, wert) => (state.emailAdresse = wert),
    setPasswort: (state, wert) => (state.passwort = wert),
    setAdresse: (state, wert) => (state.adresse = wert),
    setSignatureRequestId: (state, wert) => (state.signatureRequestId = wert),
    setZahlungsMethode: (state, wert) => (state.zahlungsMethode = wert),
    setPreisMinderung: (state, wert) => (state.preisMinderung = wert),
    setZahlungsUrl: (state, wert) => (state.zahlungsUrl = wert),
    //Besondere Mutation, die wenn möglich die Kosten für die aktuelle Konfiguration abfragt und dann die Positionen speichert
    setRechungsPositionen: (state) => {
      if (
        state.paket != null &&
        typeof state.zahlungsMethode === "string" &&
        typeof state.frist === "string" &&
        state.rechnungsPositionenLaden === false
      ) {
        state.rechnungsPositionenLaden = true;
        var data = {
          paket: state.paket.id,
          frist: state.frist,
          zm: state.zahlungsMethode,
        };

        if (state.preisMinderung != null) {
          data["preisminderung"] = state.preisMinderung.code;
        }

        funktionAufrufen("neuenAbonnementPreisAbfragen", data).then((erg) => {
          if (erg.code === 0) {
            state.testphaseRP = erg.daten.testphase;
            state.abonnementRP = erg.daten.abonnement;
            state.abonnementBeginn = erg.daten.abonnementBeginn;
            state.rechnungsPositionenLaden = false;
            console.log("Rechnungspositionen geladen.");
          } else {
            //TODO: Fehler anzeigen
            console.log(erg.code);
          }
        });
      }
    },
    setTestDaten: (state) => {
      state.agb = true;
      state.angebote = false;
      state.displayName = testRegistrierungsDaten.eigentuemer.displayName;
      state.emailAdresse = testRegistrierungsDaten.eigentuemer.email;
      state.passwort = testRegistrierungsDaten.eigentuemer.passwort;
      state.adresse = testRegistrierungsDaten.adresse;
      state.signatureRequestId = testRegistrierungsDaten.signatureRequestId;
      state.laedt = false;
    },
  },
  actions: {
    // Define your actions here
  },
  getters: {
    // Define your getters here
    getPakete: (state) => state.pakete,
    viewerDatenGueltig: (state) => {
      return (
        state.laedt === false &&
        state.agb === true &&
        state.adresse != null &&
        typeof state.displayName === "string" &&
        typeof state.emailAdresse === "string" &&
        typeof state.passwort === "string" &&
        typeof state.signatureRequestId === "string"
      );
    },
    regulaereDatenGueltig: (state) => {
      return (
        state.laedt === false &&
        state.agb === true &&
        state.adresse != null &&
        state.paket != null &&
        typeof state.displayName === "string" &&
        typeof state.emailAdresse === "string" &&
        typeof state.passwort === "string" &&
        typeof state.signatureRequestId === "string" &&
        typeof state.frist === "string" &&
        typeof state.zahlungsMethode === "string"
      );
    },
  },
});

export default store;

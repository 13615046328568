const testRegistrierungsDaten1 = {
  agb: true,
  emails: false,
  eigentuemer: {
    displayName: "Dr-Alex-Alpha",
    email: "alex@alpha.de",
    passwort: "Passwort123!",
  },
  adresse: {
    name: "Alphastrahlung Pur",
    strasse: "Mustergasse",
    hausnummer: "123",
    zusatz1: null,
    zusatz2: null,
    postleitzahl: "12345",
    stadt: "Musterstadt",
    land: "Deutschland",
  },
  signatureRequestId: "f2e80349-8fb5-4a35-9b56-30f72b98a3ca",
};

const testRegistrierungsDaten2 = {
  agb: true,
  emails: false,
  eigentuemer: {
    displayName: "Dr-Sus-Schuster",
    email: "sus@email.de",
    passwort: "Passwort123!",
  },
  adresse: {
    name: "Susklinik Offenbach",
    strasse: "Musterweg",
    hausnummer: "333",
    zusatz1: null,
    zusatz2: null,
    postleitzahl: "54321",
    stadt: "Oberloolweg",
    land: "Deutschland",
  },
  signatureRequestId: "f2e80349-8fb5-4a35-9b56-30f72b98a3ca",
};

module.exports = { testRegistrierungsDaten: testRegistrierungsDaten2 };

<template>
    <div class="w-full px-10 py-6 rounded-2xl bg-TextField flex flex-col space-y-3 items-center text-sm">
        <p class="w-full text-sm text-MittelGrau font-bold">Ihre Daten:</p>
        <div class="w-full flex flex-col justify-start items-start">
            <span>{{ name }}</span>
            <span>{{ email }}</span>
        </div>
        <p class="w-full text-sm pt-3 text-MittelGrau font-bold">Ihre Organisation</p>
        <div class="w-full flex flex-col justify-start items-start">
            <span class="mb-1">{{ orgAdresse.name }}</span>
            <div class="flex space-x-1">
                <span>{{ orgAdresse.strasse }}</span>
                <span>{{ orgAdresse.hausnummer }}</span>
            </div>
            <div v-if="orgAdresse.zusatz1 || orgAdresse.zusatz2" class="flex">
                <span v-if="orgAdresse.zusatz1">{{ orgAdresse.zusatz1 }}</span>
                <span v-if="orgAdresse.zusatz1">{{ orgAdresse.zusatz2 }}</span>
            </div>
            <div class="flex space-x-1">
                <span>{{ orgAdresse.postleitzahl }}</span>
                <span>{{ orgAdresse.stadt }}</span>
            </div>
            <span>{{ orgAdresse.land }}</span>
        </div>
    </div>
</template>
<script>
import store from '@/store';
import { computed } from 'vue';
import { nameFormatieren } from "@/dienste/helfer"

export default {
    setup() {
        const istLabor = computed(() => store.state.istViewer)

        const name = computed(() => {
            if (store.state.displayName) {
                return nameFormatieren(store.state.displayName)
            }
        })

        const email = computed(() => store.state.emailAdresse)

        const orgAdresse = computed(() => store.state.adresse)

        return {
            istLabor,
            name,
            email,
            orgAdresse
        }
    }
}
</script>
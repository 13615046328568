import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/tailwind.css";
import LottieAnimation from "lottie-web-vue";
import { auth } from "./firebase/config";
import { inMemoryPersistence } from "firebase/auth";
import "gitart-vue-dialog/dist/style.css";
import { GDialog } from "gitart-vue-dialog";
import tooltip from "./directives/tooltip";
import "@/assets/tooltip.css";
import dayjs from "dayjs";
require("dayjs/locale/de");
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

auth.setPersistence(inMemoryPersistence);

createApp(App)
  .use(router)
  .use(store)
  .use(LottieAnimation)
  .directive("tooltip", tooltip)
  .component("GDialog", GDialog)
  .mount("#app");

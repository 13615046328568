<template>
    <div :class="{ 'onboarding-komponente': mobile === false, 'onboarding-komponente-mobile': mobile === true }">
        <Titel v-if="!mobile" titel="Auftragsverarbeitung"
            untertitel="Bitte öffnen, lesen und unterschreiben Sie den Auftragsverarbeitungsvertrag nach Art. 28 DSGVO, der für DentaSnap zwischen Ihrer Organisation und der JMS Invent GmbH geschlossen wird." />
        <span v-else class="text-sm">Bitte öffnen, lesen und unterschreiben Sie den Auftragsverarbeitungsvertrag
            nach Art.
            28 DSGVO, der für
            DentaSnap zwischen Ihrer Organisation und der JMS Invent GmbH geschlossen wird.</span>
        <div class="w-full flex" :class="{ 'h-full justify-center items-start': mobile === true }">
            <DokumentZurUnterschrift :mobile="mobile" :laedt="vertragLaedt" :laedtText="vertragLadeText"
                :name="dokumentenName" :signingUrl="signingUrl" @unterzeichnet="unterzeichnet" />
        </div>
        <WeiterButton :mobile="mobile" :deaktiviert="!vertragUnterzeichnet" @klick="weiter" />
    </div>
</template>
<script>
import Titel from "@/components/Titel.vue"
import DokumentZurUnterschrift from "./components/DokumentZurUnterschrift.vue"
import WeiterButton from "@/components/WeiterButton.vue"
import { funktionAufrufen } from "@/dienste/functions"
import { onMounted, ref, computed } from "vue"
import store from "@/store"
export default {
    components: {
        Titel,
        DokumentZurUnterschrift,
        WeiterButton
    },
    props: ["mobile"],
    emits: ["weiter"],
    setup(props, context) {

        /** Ob der Vertrag aktuell geladen wird */
        const vertragLaedt = ref(true)

        const istViewer = computed(() => store.state.istViewer)

        /** Ladetext, der unter dem Vertragswidget angezeigt wird. Ist unabhängig vom
         * Ladezustand des Vertrags selbst. So kann nach dem unterzeichnen ebenfalls ein Ladezustand
         * angezeigt werden.
         */
        const vertragLadeText = ref("Dokument wird generiert...")

        /** Name des generierten AVV */
        const dokumentenName = ref(null)

        /** Deeplink, der dem YouSign iFrame übergeben wird */
        const signingUrl = ref(null)

        /** ID der "SigningRequest", die zur Vertragsunterzeichnung gehört */
        const signingRequestId = ref(null)

        /** Gibt an, ob der AVV durch den Nutzer unterzeichnet wurde */
        const vertragUnterzeichnet = ref(false)

        const weiter = () => context.emit("weiter")

        function unterzeichnet() {
            store.commit("setSignatureRequestId", signingRequestId.value)
            vertragUnterzeichnet.value = true
        }

        /**
         * Wenn alle nötigen Informationen vorhanden sind, werden Sie dem Backend zur Generierung
         * und Bereitstellung des AVV übergeben
         */
        function vertragLaden() {
            if (typeof store.state.displayName != "string" || store.state.adresse == null || store.state.emailAdresse == null) {
                return
            }

            vertragLaedt.value = true
            vertragLadeText.value = "Dokument wird generiert..."

            funktionAufrufen("avvVorbereiten", {
                adresse: store.state.adresse,
                eigentuemerEmailAdresse: store.state.emailAdresse,
                eigentuemerName: store.state.displayName,
            })
                .then(erg => {
                    if (erg.code === 0) {
                        //Vertrag erfolgreich generiert
                        dokumentenName.value = erg.daten.name
                        signingUrl.value = erg.daten.signingUrl
                        signingRequestId.value = erg.daten.signingRequestId

                        vertragLadeText.value = null
                    } else {
                        //TODO: Fehlertoast
                        console.log(erg);
                    }
                })
                .finally(() => vertragLaedt.value = false)
        }

        onMounted(vertragLaden)

        return {
            vertragLaedt,
            vertragLadeText,
            dokumentenName,
            signingUrl,
            signingRequestId,
            vertragUnterzeichnet,
            istLabor: istViewer,
            unterzeichnet,
            weiter
        }
    }
}
</script>
import { functions } from "@/firebase/config";
import { httpsCallable } from "firebase/functions";
import { versionAbfragen } from "./helfer";
import { fehlerSenden } from "./LogDienst";

const funktionAufrufen = async (name, daten) => {
  try {
    const version = versionAbfragen();

    if (daten != undefined) {
      daten.version = version;
    } else {
      daten = { version: version };
    }

    const callable = httpsCallable(functions, name);
    const ergebnis = await callable(daten);

    if (ergebnis.data.code == 91 || ergebnis.data.code == 92) {
      console.log("Instandhaltungscode zurückerhalten: ", ergebnis.data.code);
      return;
    }

    return ergebnis.data;
  } catch (error) {
    fehlerSenden(error, `Fehler beim Aufrufen der Cloud-Funktion ${name}`);
    return { code: 11 };
  }
};

export { funktionAufrufen };

<template>
    <div class="flex flex-col font-dm-sans"
        :class="{ 'rounded-xl bg-TextField px-4 py-2': mobile === false, 'w-full': mobile === true }">
        <div v-for="(zm, index) in zahlungsMethoden" :key="zm"
            class="w-full flex flex-col space-2 justify-center items-center">
            <ZahlungsMethode :mobile="mobile" :methode="zm" />
            <div v-if="index < zahlungsMethoden.length - 1" class="w-full h-[1px] rounded-full bg-LeichtesGrau opacity-30">
            </div>
        </div>
    </div>
</template>
<script>
import ZahlungsMethode from './components/ZahlungsMethode.vue';
import Checkbox from '@/components/Checkbox/Checkbox.vue';
import { zahlungsMethoden } from '@/constants';
export default {
    components: { Checkbox, ZahlungsMethode },
    props: ["mobile"],
    setup() {
        return {
            zahlungsMethoden
        }
    }
}
</script>
<template>
    <div class="w-full h-full bg-white flex justify-start p-5"
        :class="{ 'flex-col items-start': mobile === true, 'items-center': mobile === false }">
        <FortschrittsAnzeige :mobile="mobile" :schritte="schritte" :index="4" :fehler="true"
            :class="{ 'w-1/3 h-full sm:mr-36': mobile === false }" />
        <div :class="{ 'onboarding-komponente': mobile === false, 'onboarding-komponente-mobile': mobile === true }">
            <Titel v-if="mobile === false" titel="Zahlung Abgebrochen"
                untertitel="Ihre Zahlung wurde abgebrochen oder es ist ein Fehler aufgetreten. Bitte starten Sie Ihre Registrierung neu." />
            <div class="w-full flex flex-col"
                :class="{ 'h-full justify-center items-center text-center': mobile === true, 'justify-start items-start': mobile === false }">
                <LottieAnimation class="w-32 aspect-square" :animation-data="fehler" :auto-play="true" :loop="true"
                    :speed="1" ref="fehleranim" />
                <span v-if="mobile">
                    Ihre Zahlung wurde abgebrochen oder es ist ein Fehler aufgetreten. Bitte starten Sie Ihre Registrierung
                    neu.
                </span>
            </div>
            <WeiterButton :mobile="mobile" titel="Zurück" :deaktiviert="false" @klick="zurueck" />

        </div>
    </div>
</template>
<script>
import { LottieAnimation } from 'lottie-web-vue';
import fehler from "@/assets/animations/fehler.json"
import { schritte } from '@/constants';
import { useRoute, useRouter } from 'vue-router'
import FortschrittsAnzeige from '@/components/FortschrittsAnzeige/FortschrittsAnzeige.vue';
import WeiterButton from '@/components/WeiterButton.vue';
import Titel from '@/components/Titel.vue';
export default {
    components: {
        FortschrittsAnzeige,
        LottieAnimation,
        Titel,
        WeiterButton
    },
    props: ["mobile"],
    setup() {

        const route = useRoute()
        const router = useRouter()
        /**
         * Nutzer wird zur Homepage weitergeleitet. Ist in der aktuellen Route kein "p" Parameter
         * mit der ID des gewünschten Pakets gegeben, wird er von dort zur Homepage
         * weitergeleitet.
         */
        function zurueck() {
            router.push({ name: "home", query: route.query })
        }

        return {
            fehler,
            schritte,
            zurueck
        }
    }
}
</script>
<template>
    <div v-if="mobile === true"
        class="w-full h-44 rounded-md bg-FortschrittsHintergrundBlau flex flex-col justify-between items-start font-dm-sans px-6 py-5 space-y-5">
        <div class="w-full flex flex-col justify-start items-start text-white ">
            <!-- Aktueller Titel -->
            <span class="text-2xl font-bold">{{ schritte[index].titel }}</span>
            <span class="font-light opacity-60">{{ schritte[index].untertitel }}</span>
        </div>

        <!-- Fortschrittsindikatoren -->
        <div class="w-full h-3 flex space-x-1.5 justify-start items-start">
            <div v-for="s in schritte" :key="s.index" class="w-2.5 h-2.5 rounded-full" :class="{
                'bg-DentaBlue': s.index < index,
                'bg-white': s.index == index,
                'bg-Warning': s.index == index && fehler,
                'border border-MittelGrau': s.index > index
            }"></div>
        </div>
    </div>
    <div v-else
        class="rounded-md flex flex-col justify-between items-start p-10 font-dm-sans tracking-wider bg-FortschrittsHintergrundBlau">
        <div class="w-full flex flex-col justify-start items-start space-y-14">
            <img src="@/assets/ds-logos/icon_rounded.png" class="w-12 h-12">
            <div class="w-full flex flex-col justify-start items-start ml-10 pr-24">
                <!-- Elemente -->
                <div v-for="schritt in schritte" :key="schritt.index" class="flex justify-start items-start">
                    <div class="flex flex-col justify-start items-center mr-5">
                        <div class="w-8 h-8 rounded-full" :class="{
                            'bg-DentaBlue': schritt.index < index,
                            'bg-white': schritt.index == index,
                            'bg-Warning': schritt.index == index && fehler,
                            'border-2 border-MittelGrau': schritt.index > index
                        }">
                        </div>
                        <div v-if="schritt.index < schritte.length - 1"
                            class="w-0.5 h-10 rounded-full bg-MittelGrau opacity-30 my-4">
                        </div>
                    </div>
                    <div class="flex flex-col justify-start items-start text-LeichtesGrau space-y-1">
                        <p class="font-bold">{{ schritt.titel }}</p>
                        <p class="font-light opacity-60">{{ schritt.untertitel }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full flex justify-between items-end text-LeichtesGrau font-light">
            <p class="transition duration-100 hover:cursor-pointer hover:opacity-80">JMS Invent GmbH</p>
            <p class="transition duration-100 hover:cursor-pointer hover:opacity-80">Support</p>
        </div>
    </div>
</template>

<script>
export default {
    props: ["mobile", "schritte", "index", "fehler"],
}
</script>
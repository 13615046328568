<template>
    <!-- Karte -->
    <button
        class="bg-TextField aspect-paketkarte rounded-3xl px-8 py-10 flex flex-col justify-between space-y-10 border"
        :class="[aktivHighlight, { 'w-80': mobile === false, 'w-full': mobile === true }]" @click="ausgewaehlt">
        <!-- Header -->
        <div class="flex flex-col justify-start items-start space-y-2">
            <p class=" text-3xl font-bold">{{ paket.name }}</p>
            <div class="w-1/6 h-0.5 rounded-full" :class="[akzent]"></div>
            <p class="text-sm font-light">{{ paket.beschreibung }}</p>
        </div>

        <!-- Informationen -->
        <div class="w-full flex flex-col">
            <!-- Speicher -->
            <div class="w-full flex justify-center items-center text-MittelGrau">
                <div class="w-1/4 flex justify-end mr-3" :class="akzentText">{{ speicher }}</div>
                <div class="w-3/4 flex justify-start font-light">GB Cloud Speicher</div>
            </div>

            <!-- Nutzer -->
            <div class="w-full flex justify-center items-center text-MittelGrau">
                <div class="w-1/4 flex justify-end mr-3" :class="akzentText">{{ paket.nutzer }}</div>
                <div class="w-3/4 flex justify-start font-light">Individuelle Nutzer</div>
            </div>

            <!-- iOS App -->
            <div class="w-full flex justify-center items-center text-MittelGrau">
                <div class="w-1/4 flex justify-end mr-3"><img :src="haken" class="w-3 aspect-square"></div>
                <div class="w-3/4 flex justify-start font-light">iOS App</div>
            </div>

            <!-- Web App -->
            <div class="w-full flex justify-center items-center text-MittelGrau">
                <div class="w-1/4 flex justify-end mr-3"><img :src="haken" class="w-3 aspect-square"></div>
                <div class="w-3/4 flex justify-start font-light">Web App</div>
            </div>

            <!-- DSGVO Vorlagen -->
            <div class="w-full flex justify-center items-center text-MittelGrau">
                <div class="w-1/4 flex justify-end mr-3"><img :src="haken" class="w-3 aspect-square"></div>
                <div class="w-3/4 flex justify-start font-light">DSGVO Vorlagen</div>
            </div>
        </div>

        <!-- Preis -->
        <p class="w-full text-center text-xl" :class="akzentText">{{ preis }}</p>
    </button>
</template>
<script>
import { computed, onMounted } from 'vue';
import { preisStringGenerieren } from '@/dienste/helfer';
import { mehrwertSteuerSatz } from '@/constants';
import store from '@/store';
export default {
    props: ["mobile", "paket"],
    emits: ["gewaehlt"],
    setup(props, context) {

        onMounted(() => console.log(props.mobile))

        const aktuelleFrist = computed(() => store.state.frist)
        const aktuellesPaket = computed(() => store.state.paket)

        /* Klasse wird für das einfärben eines Element-Hintergrunds mit der regulären Akzentfarbe verwendet */
        const akzent = computed(() => {
            if (props.paket) {
                switch (props.paket.rang) {
                    case 0: return "bg-[#72D7DD]"
                    case 1: return "bg-[#007AFF]"
                    default: return "bg-[#C05BE3]"
                }
            }
        })

        /* Klasse wird für das einfärben eines Element-Hintergrunds mit der Hintergrundversion der Akzentfarbe verwendet */
        const akzentHintergrund = computed(() => {
            if (props.paket) {
                switch (props.paket.rang) {
                    case 0: return "bg-[#EEFAFB]"
                    case 1: return "bg-[#DDECFF]"
                    default: return "bg-[#F7EAFB]"
                }
            }
        })

        /* Klasse wird für das einfärben eines Element-Textes mit der regulären Akzentfarbe verwendet */
        const akzentText = computed(() => {
            if (props.paket) {
                switch (props.paket.rang) {
                    case 0: return "text-[#72D7DD]"
                    case 1: return "text-[#007AFF]"
                    default: return "text-[#C05BE3]"
                }
            }
        })

        const aktivHighlight = computed(() => {
            if (props.paket.id === aktuellesPaket.value.id) {
                switch (props.paket.rang) {
                    case 0: return "bg-[#EEFAFB] border-[#72D7DD]"
                    case 1: return "bg-[#DDECFF] border-[#007AFF]"
                    default: return "bg-[#F7EAFB] border-[#C05BE3]"
                }
            } else {
                return 'border-transparent'
            }
        })

        const speicher = computed(() => {
            if (props.paket) {
                return props.paket.kapazitaet
            }
        })

        const haken = computed(() => {
            if (props.paket) {
                switch (props.paket.rang) {
                    case 0: return require("@/assets/icons/haken-gruen.svg")
                    case 1: return require("@/assets/icons/haken-blau.svg")
                    default: return require("@/assets/icons/haken-violett.svg")
                }
            }
        })

        const preis = computed(() => {
            const preis = aktuelleFrist.value === "j" ? props.paket.jahresNettoPreis : props.paket.monatsNettoPreis
            return `${preisStringGenerieren(parseFloat((preis * mehrwertSteuerSatz).toFixed(2)))}€ / ${aktuelleFrist.value === "j" ? 'Jahr' : 'Monat'}`
        })

        const ausgewaehlt = () => store.commit("setPaket", props.paket)

        return {
            akzent,
            akzentHintergrund,
            akzentText,
            speicher,
            haken,
            aktivHighlight,
            preis,
            preisStringGenerieren,
            ausgewaehlt
        }
    }
}
</script>
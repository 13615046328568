<template>
    <div class="w-full h-full bg-white flex justify-start p-5 font-dm-sans"
        :class="{ 'items-center': mobile === false, 'flex-col items-start': mobile === true }">
        <FortschrittsAnzeige :mobile="mobile" :schritte="praxisSchritte" :index="6" :fehler="false"
            :class="{ 'w-1/3 h-full sm:mr-36': mobile === false }" />
        <NaechsteSchritteView :mobile="mobile" :bearbeitung="bearbeitung" />
    </div>
</template>
<script>
import NaechsteSchritteView from '@/views/naechste-schritte/components/NaechsteSchritteView.vue';
import FortschrittsAnzeige from '@/components/FortschrittsAnzeige/FortschrittsAnzeige.vue';
import { praxisSchritte } from '@/constants';
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { signInWithCustomToken } from 'firebase/auth';
import { auth } from '@/firebase/config';
import { funktionAufrufen } from '@/dienste/functions';
import mail from "@/assets/animations/mail.json"
import ladenBlau from "@/assets/animations/laden-blau.json"
export default {
    components: {
        NaechsteSchritteView,
        FortschrittsAnzeige,
    },
    props: ["mobile"],
    setup() {
        const bearbeitung = ref(false)

        const route = useRoute()
        const router = useRouter()

        const isViewer = ref(false)

        async function zahlungBearbeiten() {
            //Param Check
            if (typeof route.query.token !== "string") {
                router.push({ name: "home" })
            }

            //Anmeldeversuch mit dem empfangenen Token
            try {
                await signInWithCustomToken(auth, route.query.token)
            } catch (error) {
                router.push({ name: "home" })
            }

            // //Aufruf des "praxisAktivieren" Endpunkts
            const aktivierungRes = await funktionAufrufen("praxisAktivieren")

            if (aktivierungRes.code === 0) {
                //Aktivierung erfolgreich
                bearbeitung.value = false
            } else {
                //Aktivierung nicht erfolgreich
                console.log(aktivierungRes);
                router.push({ name: "home" })
            }

        }

        onMounted(zahlungBearbeiten)

        return {
            praxisSchritte,
            mail,
            bearbeitung,
            ladenBlau
        }
    }
}
</script>
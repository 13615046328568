<template>
    <!-- Unterzeichnen Container -->
    <GDialog v-model="dialogState" max-width="900" :persistent="true">
        <div class="w-full h-[48rem] bg-white flex justify-center items-center relative">
            <div class="w-full h-full absolute" id="iframe-container"></div>
            <div class="w-full h-full absolute z-10 flex justify-start items-end p-[16px] pointer-events-none">
                <button class="text-[#9D9D9D] pointer-events-auto h-[40px] px-[24px]"
                    @click="dialogState = false">Schliessen</button>
            </div>
        </div>
    </GDialog>
    <!-- Dokument -->
    <div class="w-full flex flex-col justify-start items-center space-y-5 pt-10">
        <div class="w-full px-8 py-12 rounded-[1.5rem] bg-[#F6F8FA] flex justify-between items-center">
            <div v-if="bereit === true" class="flex space-x-4">
                <img src="@/assets/icons/dokument-blau.png" class="w-6 aspect-square object-contain">
                <p>{{ name }}</p>
            </div>
            <div v-else class="w-full flex space-x-4">
                <div class="w-6 h-6 rounded-lg bg-LeichtesGrau bg-opacity-30"></div>
                <div class="w-1/2 h-6 rounded-lg bg-LeichtesGrau bg-opacity-30"></div>
            </div>

            <div v-if="bereit === true">
                <button v-if="signed === false" class="flex space-x-2 items-center text-DentaBlue" @click="oeffnenGeklickt">
                    <p>Öffnen</p>
                    <img src="@/assets/icons/oeffnen-blau.png" alt="open" class="w-4 aspect-square object-contain">
                </button>
                <img v-else src="@/assets/icons/haken-gruen.png" class="w-5 aspect-square object-contain">
            </div>
            <div v-else class="w-24 h-6 rounded-lg bg-LeichtesGrau bg-opacity-30"></div>
        </div>

        <!-- Ladeindikator -->
        <div class="h-10">
            <div v-if="laedtText != null" class="h-full flex justify-center items-center space-x-3">
                <p>{{ laedtText }}</p>
            </div>
        </div>
    </div>
</template>
  
<script>
import { LottieAnimation } from 'lottie-web-vue';
import { computed, nextTick, ref } from 'vue';
import { GDialog } from 'gitart-vue-dialog';
import ladenBlau from "@/assets/animations/laden-blau.json"
export default {
    props: ["mobile", "laedt", "laedtText", "name", "signingUrl"],
    emits: ["unterzeichnet"],
    components: { GDialog, LottieAnimation },
    setup(props, context) {
        const signed = ref(false)
        const dialogState = ref(false)

        const bereit = computed(() => props.laedt === false && props.signingUrl != null && props.name != null)

        /**
         * Lädt das Skript für YouSign (wenn noch nicht vorhanden), und erstellt damit eine Yousign instanz mit dem "Signing Url"
         * Der IFrame wird dann am Container mit der gegebenen ID gemountet
         * @param {string} signingUrl Von YouSign API erhaltener "Signing URL"
         * @param {string} containerId ID des HTML ELements, auf den der fertige IFrame gemountet wird.
         */
        function youSignIframeAnzeigen(signingUrl, containerId) {
            // Function to initialize YouSign
            function initializeYouSign() {
                //Der Dialog wird zunächst angezeigt
                dialogState.value = true

                nextTick(() => {
                    const ys = new Yousign({
                        signatureLink: signingUrl, // Use passed signingUrl
                        iframeContainerId: containerId, // Use passed containerId
                        //TODO: Für Production Umgebung false setzen, env vars nutzen!
                        isSandbox: true, // Set to false in production
                    });

                    //Listener für die Erfolgs -und Fehlerzustände werden hinzugefügt
                    ys.onSuccess(() => {
                        signed.value = true
                        dialogState.value = false
                        context.emit("unterzeichnet")
                    })
                })
            }

            // Check if the script is already loaded
            const scriptURL = "https://cdn.yousign.tech/iframe-sdk-1.5.0.min.js";
            const existingScript = document.querySelector(`script[src="${scriptURL}"]`);

            if (existingScript) {
                // If the script is already present, run the initialization code
                initializeYouSign();
            } else {
                // If the script is not present, create and load it
                const script = document.createElement("script");
                script.src = scriptURL;
                script.integrity = "sha384-wrNTU81R+b2K+9A1aE1c3blhReNpdRK6Tn1fzXOXhWGZGUk+Btp0dyfnk45pVpOr";
                script.crossOrigin = "anonymous";

                document.head.appendChild(script);

                // Once the script is loaded, initialize YouSign
                script.onload = initializeYouSign;

                // Optional: Handle script loading errors
                script.onerror = () => {
                    //TODO: Fehlermeldung mittels Logsystem implementieren
                    console.error("Error loading the YouSign script.");
                };
            }
        }

        function oeffnenGeklickt() {
            youSignIframeAnzeigen(props.signingUrl, "iframe-container")
        }

        return {
            dialogState,
            signed,
            bereit,
            ladenBlau,
            oeffnenGeklickt
        }
    }
}
</script>
<style>
#iframe-container iframe {
  width: 100%;
  height: 100%;
}
</style>
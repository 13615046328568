import { createRouter, createWebHistory } from "vue-router";
import Registrierung from "@/pages/Registrierung.vue";
import Abschliessen from "@/pages/Abschliessen.vue";
import Fehler from "@/pages/Fehler.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Registrierung,
  },
  {
    path: "/abschliessen",
    name: "abschliessen",
    component: Abschliessen,
  },
  {
    path: "/fehler",
    name: "fehler",
    component: Fehler,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: { name: "home" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

const agbTemp =
  "Allgemeine Geschäftsbedingungen für die Nutzung der Web-App mit Benutzerauthentifizierung\n\nGeltungsbereich\nDiese Allgemeinen Geschäftsbedingungen gelten für die Nutzung der Web-App, einschließlich aller damit verbundenen Dienste und Funktionen, die von [Name des Anbieters] bereitgestellt werden.\n\nRegistrierung und Benutzerkonto\nUm die Web-App nutzen zu können, müssen Sie sich registrieren und ein Benutzerkonto erstellen. Die von Ihnen bereitgestellten Informationen müssen wahrheitsgemäß und aktuell sein.\n\nVerantwortung für das Benutzerkonto\nSie sind allein verantwortlich für die sichere Aufbewahrung Ihrer Anmeldeinformationen und für alle Aktivitäten, die über Ihr Benutzerkonto ausgeführt werden.\n\nÄnderungen der Allgemeinen Geschäftsbedingungen\n[Name des Anbieters] behält sich das Recht vor, diese Allgemeinen Geschäftsbedingungen jederzeit zu ändern. Die aktuelle Version wird stets auf der Web-App veröffentlicht.\n\nHaftungsausschluss\n[Name des Anbieters] übernimmt keine Haftung für Schäden, die aus der Nutzung der Web-App entstehen, sofern diese nicht auf einer grob fahrlässigen oder vorsätzlichen Pflichtverletzung von [Name des Anbieters] beruhen.\n\nBeendigung der Nutzung\nSie können Ihr Benutzerkonto jederzeit ohne Angabe von Gründen kündigen. [Name des Anbieters] behält sich das Recht vor, Ihr Benutzerkonto jederzeit und ohne Angabe von Gründen zu kündigen oder zu sperren.\n\nSchlussbestimmungen\nDiese Allgemeinen Geschäftsbedingungen unterliegen dem Recht der Bundesrepublik Deutschland. Gerichtsstand ist [Ort].\n\nStand: [Datum der Veröffentlichung]\n\n";

const emailPruefRegex =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

const entwicklung = true;

/**
 * Alle Schritte, die während der Registrierung durchlaufen werden. Zu jedem Schritt ist eine Seite vorhanden.
 */
const praxisSchritte = [
  {
    index: 0,
    titel: "AGB & Datenschutz",
    untertitel: "Unsere AGB & Datenschutzbestimmungen.",
  },
  {
    index: 1,
    titel: "Ihr Konto",
    untertitel: "Richten Sie Ihr DentaSnap Konto ein.",
  },
  {
    index: 2,
    titel: "Organisation",
    untertitel: "Informationen zu Ihrer DentaSnap Organisation.",
  },
  {
    index: 3,
    titel: "Auftragsverarbeitung",
    untertitel: "AVV zwischen JMS Invent und Ihrer Organisation.",
  },
  {
    index: 4,
    titel: "Zahlung",
    untertitel: "Wie und wann möchten Sie zahlen?",
  },
  {
    index: 5,
    titel: "Zusammenfassung",
    untertitel: "Dies ist Ihr DentaSnap Abonnement.",
  },
  {
    index: 6,
    titel: "Fertig",
    untertitel: "Nächste Schritte mit DentaSnap.",
  },
];

const laborSchritte = [
  {
    index: 0,
    titel: "AGB & Datenschutz",
    untertitel: "Unsere AGB & Datenschutzbestimmungen.",
  },
  {
    index: 1,
    titel: "Ihr Konto",
    untertitel: "Richten Sie Ihr DentaSnap Konto ein.",
  },
  {
    index: 2,
    titel: "Ihre Organisation",
    untertitel: "Informationen zu Ihrer DentaSnap Organisation.",
  },
  {
    index: 3,
    titel: "Auftragsverarbeitung",
    untertitel: "AVV zwischen JMS Invent und Ihrer Organisation.",
  },
  {
    index: 4,
    titel: "Zusammenfassung",
    untertitel: "Dies ist Ihre DentaSnap Registrierung.",
  },
  {
    index: 5,
    titel: "Fertig",
    untertitel: "Nächste Schritte mit DentaSnap.",
  },
];

const zahlungsMethoden = ["creditcard", "paypal", "giropay"];

const mehrwertSteuerSatz = 1.19;

const anreden = ["Herr", "Frau", "Dr.", "Prof.", "Prof. Dr."];
const laender = ["Deutschland"];

//TODO: Remove after testing
const testKostenZusammenstellung = {
  praxisId: "3b97df8a-b991-46ad-b4f1-3716fd129783",
  zusammenstellung: [
    {
      index: 0,
      art: "grundpreis",
      beschreibung: "DentaSnap Pro, Monatliche Zahlung",
      wert: 69.0,
    },
    {
      index: 1,
      art: "preisminderung",
      beschreibung: "Code 'Earlybird30'",
      wert: 20.7,
    },
    {
      index: 3,
      art: "netto",
      beschreibung: "Netto",
      wert: 48.2,
    },
    {
      index: 4,
      art: "mwst",
      beschreibung: "MwST (19%)",
      wert: 9.16,
    },
    {
      index: 5,
      art: "brutto",
      beschreibung: "Gesamt",
      wert: 57.36,
    },
  ],
};

const testRechnungsAdresse = {
  strasse: "Musterstraße",
  hausnummer: "11A",
  zusatz1: null,
  zusatz2: null,
  postleitzahl: "12345",
  stadt: "Musterstadt",
  land: "Deutschland",
};

export {
  agbTemp,
  emailPruefRegex,
  entwicklung,
  praxisSchritte,
  laborSchritte,
  zahlungsMethoden,
  mehrwertSteuerSatz,
  anreden,
  laender,
  testKostenZusammenstellung,
  testRechnungsAdresse,
};

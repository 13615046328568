<template>
    <form @submit.prevent
        :class="{ 'onboarding-komponente h-screen justify-center': mobile === false, 'onboarding-komponente-mobile': mobile === true }">
        <Titel v-if="!mobile" titel="Zusammenfassung" untertitel="Dies ist Ihr DentaSnap Abonnement."
            :hatZurueckButton="true" @zurueck="zurueck" />
        <ZurueckButton v-else @click="zurueck" />
        <div class="w-full flex flex-col space-y-6" :class="{ 'max-h-[70%] overflow-y-auto pr-6': mobile === false }">
            <KostenTestphase v-if="!istLabor" />
            <KostenRegulaer v-if="!istLabor" :mobile="mobile" />
            <Zahlungsmethode v-if="!istLabor" />
            <PersoenlicheDaten />
        </div>
        <div class="w-full flex justify-start pb-10">
            <WeiterButton :mobile="mobile" :width="istLabor ? '18rem' : '22rem'"
                :titel="istLabor ? 'Registrierung Durchführen' : 'Abonnement Kostenpflichtig Starten'"
                :deaktiviert="rechnungsPositionenLaden" :laedt="laedt" @klick="ctaClick" />
        </div>
    </form>
</template>
<script>
import Titel from '@/components/Titel.vue';
import WeiterButton from '@/components/WeiterButton.vue';
import KostenTestphase from './components/KostenTestphase.vue';
import KostenRegulaer from './components/KostenRegulaer.vue';
import Rechnungsadresse from './components/Rechnungsadresse.vue';
import Zahlungsmethode from './components/Zahlungsmethode.vue';
import ZurueckButton from '@/components/ZurueckButton.vue';
import PersoenlicheDaten from './components/PersoenlicheDaten.vue';
import { computed, onMounted, ref } from 'vue';
import { funktionAufrufen } from "@/dienste/functions"
import { fehlerSenden } from '@/dienste/LogDienst';
import store from '@/store';
export default {
    components: {
        Titel,
        WeiterButton,
        KostenTestphase,
        KostenRegulaer,
        Rechnungsadresse,
        Zahlungsmethode,
        ZurueckButton,
        PersoenlicheDaten
    },
    props: ["mobile"],
    emits: ["zurueck", "weiter"],
    setup(props, context) {

        const zurueck = () => context.emit("zurueck")

        const istViewer = computed(() => store.state.istViewer)

        const laedt = ref(false)
        const rechnungsPositionenLaden = computed(() => store.state.rechnungsPositionenLaden)

        function viewerRegistrierungStarten() {
            if (store.getters.viewerDatenGueltig && !laedt.value) {
                laedt.value = true
                funktionAufrufen("viewerRegistrierungDurchfuehren", {
                    agb: store.state.agb,
                    emails: store.state.angebote,
                    email: store.state.emailAdresse,
                    passwort: store.state.passwort,
                    displayName: store.state.displayName,
                    adresse: store.state.adresse,
                    signatureRequestId: store.state.signatureRequestId
                })
                    .catch(err => {
                        fehlerSenden(err, "Fehler beim Durchführen einer Viewer-Registrierung", "viewerRegistrierungStarten", "Zusammenfassung.vue")
                        laedt.value = false
                    })
                    .then(erg => {
                        if (erg.code === 0) {
                            //Labor wurde erstellt, und ein Anmeldetoken für den neuen Eigentümer zurückgegeben.
                            store.state.anmeldeToken = erg.daten.anmeldeToken
                            context.emit("weiter")
                        }
                        laedt.value = false
                    })
            }
        }

        function regulaereRegistrierungStarten() {
            if (store.getters.regulaereDatenGueltig === true && laedt.value === false) {
                laedt.value = true
                funktionAufrufen("regulaereRegistrierungStarten", {
                    agb: store.state.agb,
                    emails: store.state.angebote,
                    email: store.state.emailAdresse,
                    passwort: store.state.passwort,
                    displayName: store.state.displayName,
                    paket: store.state.paket.id,
                    frist: store.state.frist,
                    methode: store.state.zahlungsMethode,
                    preisminderung: store.state.preisMinderung != null ? store.state.preisMinderung.code : null,
                    adresse: store.state.adresse,
                    signatureRequestId: store.state.signatureRequestId
                })
                    .catch(err => {
                        fehlerSenden(err, "Fehler beim Starten einer regulären Registrierung", "registrierungsZahlungStarten", "Zusammenfassung")
                        laedt.value = false
                    })
                    .then(res => {
                        if (res.code === 0) {
                            //Der Nutzer wird zur Zahlung via dem Mollie Gateway weitergeleitet
                            window.location.href = res.daten.weiterleitung
                        } else {
                            laedt.value = false
                        }
                    })
            }
        }

        function ctaClick() {
            if (istViewer.value) {
                viewerRegistrierungStarten()
            } else {
                regulaereRegistrierungStarten()
            }
        }

        return {
            laedt,
            rechnungsPositionenLaden,
            istLabor: istViewer,
            zurueck,
            ctaClick
        }
    }
}
</script>
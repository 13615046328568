<template lang="">
    <div class="onboarding-komponente">
            <Titel v-if="mobile === false" :titel="bearbeitung === true ? 'Bearbeitung' : 'Fertig'"
                :untertitel="bearbeitung === true ? 'Wir bearbeiten Ihre Bestellung. Bitte aktualisieren Sie die Seite nicht.' : 'Ihre Registrierung wurde abgeschlossen.'" />
            <div class="w-full flex justify-start items-center h-80">
                <div v-if="bearbeitung === false" class="w-full h-full flex flex-col justify-center"
                    :class="{ 'items-start': mobile === false, 'items-center text-center': mobile === true }">
                    <LottieAnimation :animation-data="mail" :loop="true" class="w-40" />
                    <span class="w-80 text-sm">Vielen Dank! Eine E-Mail mit allen notwendigen Informationen wurde an Sie
                        versendet. Es ist sicher, diesen Tab zu schließen.</span>
                </div>
                <LottieAnimation v-else :animation-data="ladenBlau" :loop="true" class="w-32" />
            </div>
        </div>
</template>
<script>
import Titel from '@/components/Titel.vue';
import WeiterButton from '@/components/WeiterButton.vue';
import { LottieAnimation } from 'lottie-web-vue';
import mail from "@/assets/animations/mail.json"
import ladenBlau from "@/assets/animations/laden-blau.json"
export default {
    props: ["mobile", "bearbeitung"],
    components: { Titel, WeiterButton, LottieAnimation },
    setup(props, context) {

        return {
            mail,
            ladenBlau
        }
    }
}
</script>
<template>
    <button class="h-14 font-bold rounded-full" :class="{
        'w-[10.5rem] text-lg': mobile === false,
        'w-[40%]': mobile === true,
        'bg-black text-white ': aktuelleFrist === frist,
        'bg-offwhite text-black transition duration-150 hover:bg-[#DFE0E0]': aktuelleFrist !== frist
    }">
        <slot></slot>
    </button>
</template>
<script>
import store from '@/store';
import { computed, onMounted } from 'vue';

export default {
    props: ["mobile", "frist"],
    setup() {
        const aktuelleFrist = computed(() => store.state.frist)

        onMounted(() => {
            if (typeof aktuelleFrist.value !== "string") {
                store.commit("setFrist", 'j')
            }
        })

        return { aktuelleFrist }
    }
}
</script>
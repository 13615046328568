<template>
    <div class="w-full h-full flex justify-evenly items-center font-dm-sans">
        <LottieAnimation v-if="laedt === true" :animation-data="ladenBlau" :loop="true" />
        <div v-else-if="mobile === false" class="w-full h-full relative">
            <div class="absolute z-10 w-full h-full p-20 flex justify-end items-start pointer-events-none">
                <button @click="schliessen"><img
                        class="w-12 h-12 object-contain pointer-events-auto transition duration-100 hover:opacity-70"
                        src="@/assets/icons/schliessen-schwarz.svg" alt="x"></button>
            </div>
            <div class="abolute w-full h-full flex justify-center items-center">
                <div class="w-full sm:w-[70rem] h-full flex flex-col space-y-6 justify-center items-center">
                    <div class="w-full flex flex-col justify-center items-center space-y-3">
                        <img src="@/assets/icons/logo-text.png" alt="DentaSnap" class="h-6">
                        <p class="text-5xl font-bold">Verfügbare Pakete</p>
                    </div>
                    <div class="w-full flex flex-col justify-center items-center space-y-16">
                        <div class="flex space-x-3">
                            <FristWechselButton class="w-40" frist="m" @click="fristAktualisiert('m')">Monatlich
                            </FristWechselButton>
                            <FristWechselButton class="w-40" frist="j" @click="fristAktualisiert('j')">
                                <div class="flex flex-col justify-center items-center">
                                    <p>Jährlich</p>
                                    <p class="text-sm font-light mt-[-5px]">10% sparen</p>
                                </div>
                            </FristWechselButton>
                        </div>
                        <div class="w-full flex justify-center items-center space-x-14">
                            <Paket v-for="p in pakete" :key="p.id" :paket="p" :mobile="mobile" :wechselButton="true" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="w-full h-full flex flex-col space-y-12 px-5 py-9 overflow-y-auto">
            <!-- Header -->
            <div class="w-full flex flex-col justify-center items-center space-y-4">
                <div class="w-full flex justify-end">
                    <button @click="schliessen"><img src="@/assets/icons/schliessen-schwarz.svg" alt="x"
                            class="w-10 h-10"></button>
                </div>

                <img src="@/assets/icons/logo-text.png" alt="DentaSnap" class="w-2/5">
                <p class="text-3xl font-bold">Verfügbare Pakete</p>
            </div>

            <!-- Monats / Jahreswahl -->
            <div class="w-full flex justify-evenly">
                <FristWechselButton frist="m" :mobile="mobile" @click="fristAktualisiert('m')">Monatlich
                </FristWechselButton>
                <FristWechselButton frist="j" :mobile="mobile" @click="fristAktualisiert('j')">
                    <div class="flex flex-col justify-center items-center">
                        <p>Jährlich</p>
                        <p class="text-sm font-light mt-[-5px]">10% sparen</p>
                    </div>
                </FristWechselButton>
            </div>

            <!-- Pakete -->
            <div class="w-full flex flex-col space-y-6">
                <Paket v-for="p in pakete" :key="p.id" :paket="p" :wechselButton="true" />
            </div>
        </div>
    </div>
</template>
<script>
import FristWechselButton from "./components/FristWechselButton.vue";
import ladenBlau from "@/assets/animations/laden-blau.json"
import { fehlerSenden } from "@/dienste/LogDienst";
import { firestore } from "@/firebase/config";
import { collection, getDocs } from "firebase/firestore";
import { LottieAnimation } from 'lottie-web-vue';
import { computed, onMounted, ref } from 'vue';
import { useStore } from "vuex";
import Paket from "./components/Paket.vue";
export default {
    components: { LottieAnimation, Paket, FristWechselButton },
    props: ["mobile"],
    emits: ["schliessen"],
    setup(_, context) {
        const store = useStore()

        const laedt = ref(true)
        const pakete = computed(() => store.state.pakete)
        const frist = computed(() => store.state.frist)
        const aktuellesPaket = ref(store.state.paket)

        const fristAktualisiert = (frist) => store.commit("setFrist", frist)
        const paketAktualisiert = (paket) => store.commit("setPaket", paket)
        const schliessen = () => context.emit("schliessen")



        async function initialisieren() {
            if (!store.state.pakete || !store.state.pakete.length) {
                try {
                    laedt.value = true

                    const querySnapshot = await getDocs(collection(firestore, "pakete"));
                    const paketeList = querySnapshot.docs.map(doc => doc.data()).sort((a, b) => a.rang - b.rang);

                    store.commit('setPakete', paketeList);

                    console.log(store.state.pakete);

                    laedt.value = false;
                } catch (error) {
                    fehlerSenden(error, "Fehler beim Abfragen der verfügbaren Pakete", "initialisieren", "PaketWechseln.vue")
                }
            } else {
                laedt.value = false;
            }
        }

        onMounted(initialisieren)

        return {
            laedt,
            ladenBlau,
            pakete,
            frist,
            fristAktualisiert,
            paketAktualisiert,
            schliessen,
        }
    }
}
</script>
/**
 * Generiert einen "Versionsstring", der an Backendfunktionen zur Versionskontrolle übergeben wird
 * @returns {string} Versionsstring
 */
function versionAbfragen() {
  const version =
    process.env.VUE_APP_VERSION != null ? process.env.VUE_APP_VERSION : 0;
  return `registrierung-${version}`;
}

/**
 * Rechnet einen ganzzahligen Preis (in Cent) in einen Komma getrennten "Currency String" um.
 * @param {number} preis Gannzahliger Preis (in Cents)
 * @returns {string} Preis als "Currency String"
 */
function preisStringGenerieren(preis) {
  return preis.toFixed(2).replaceAll(".", ",");
}

/**
 * Entnimmt der gegebenen Liste von Rechnungspositionen den Bruttowert, und konvertiert Ihn in einen anzeigbaren String, gerundet auf 2 Dezimalstellen.
 * @param {{index: number, art: string, beschreibung: string, wert: number}[]} rechnungsPositionen Rechnungspositionen
 * @return {string} Bruttobetrag als String, der an Mollie Zahlung übergeben werden kann
 */
function bruttoStringAusPositionen(rechnungsPositionen) {
  return rechnungsPositionen
    .find((p) => p.art === "brutto")
    .wert.toFixed(2)
    .replace(".", ",");
}

/** Generiert mit der Frist einen allgemeinen Abrechnungshinweis für jede Zahlungsmethode. Muss als HTML Element eingebunden werden! */
const allgemeinenAbrechnungsHinweisGenerieren = (frist) =>
  `Mit Ihrem Abonnementstart autorisieren Sie die JMS Invent GmbH zur ${
    frist === "j" ? "jährlichen" : "monatlichen"
  } automatischen Fakturierung bis zur Kündigung. Preisänderungen werden vorab kommuniziert. Details zur automatischen Verlängerung finden Sie in Ihren Praxiseinstellungen. Es gelten die <a>allgemeinen Geschäftsbedingungen.</a>`;

/**
 * Gibt einen formatierten Namensstring, basierend auf dem "displayName" Feld im Authnutzer, zurück.
 * @param {String} name Name, der Formatiert werden soll. Bleibt der Wert leer, wird der Name des angemeldeten Nutzers formatiert.
 * @returns {String?} Formatierter Name
 */
function nameFormatieren(name = null) {
  if (name != null) {
    let split = name.split("-");
    let anrede = split[0].toLowerCase();
    var anredeTransformiert;

    if (anrede.includes("dr")) {
      anredeTransformiert = "Dr. ";
    } else if (anrede.includes("prof")) {
      anredeTransformiert = "Prof. ";
    } else {
      anredeTransformiert = "";
    }

    return `${anredeTransformiert} ${split[1]} ${split[2]}`;
  } else return null;
}

export {
  versionAbfragen,
  preisStringGenerieren,
  bruttoStringAusPositionen,
  allgemeinenAbrechnungsHinweisGenerieren,
  nameFormatieren,
};

<template>
  <router-view class="w-full h-screen" :mobile="mobile" />
</template>

<script>
import { LottieAnimation } from 'lottie-web-vue';
import { computed, onMounted, ref } from 'vue';
import ladenBlau from "@/assets/animations/laden-blau.json"
import store from './store';
export default {
  components: {
    LottieAnimation
  },
  setup() {
    const laedt = computed(() => store.state.laedt)

    const mobile = ref(false)
    function updateMobile() {
      mobile.value = window.innerWidth <= 768;
    }

    onMounted(() => {
      window.addEventListener('resize', updateMobile);
      updateMobile()
    })

    return { ladenBlau, laedt, mobile }
  }
}
</script>
<style>
a {
  color: #007AFF !important;
}

a:hover {
  text-decoration: underline;
}

</style>



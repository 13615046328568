<template>
    <div class="w-full px-10 py-6 rounded-2xl bg-TextField flex flex-col space-y-3 items-center text-sm">
        <p class="w-full text-sm text-MittelGrau font-bold">Sofort:</p>
        <!-- Grundpreis Testphase -->
        <div v-if="laedt === false" class="w-full flex justify-between items-end">
            <span :class="{ 'w-[70%]': mobile === true }">{{ testGp.beschreibung }}:</span>
            <span>{{ positionZuWertString(testGp) }}</span>
        </div>
        <div v-else class="w-full flex justify-between">
            <LadeElement class="w-[40%]" />
            <LadeElement class="w-24" />
        </div>

        <Divider />

        <!-- Brutto Testphase -->
        <div v-if="laedt === false" class="w-full flex justify-between items-end font-bold">
            <span>{{ testBrutto.beschreibung }}:</span>
            <span>{{ positionZuWertString(testBrutto) }}</span>
        </div>
        <div v-else class="w-full flex justify-between">
            <LadeElement class="w-[40%]" />
            <LadeElement class="w-24" />
        </div>
    </div>
</template>
<script>
import LadeElement from "./LadeElement.vue"
import Divider from "./Divider.vue"
import { computed } from 'vue';
import { preisStringGenerieren } from "@/dienste/helfer";
import store from "@/store";
export default {
    components: { LadeElement, Divider },
    setup(props, context) {

        /** Konvertiert ein "Rechnungsposition" Objekt in einen "x,yz €" String*/
        function positionZuWertString(rechnungsposition) {
            return `${preisStringGenerieren(rechnungsposition.wert)} €`
        }

        const laedt = computed(() => store.state.rechnungsPositionenLaden)

        const jaehrlich = computed(() => store.state.frist === "j")

        const testGp = computed(() => {
            if (laedt.value === false) {
                return store.state.testphaseRP.find(k => k.art === "grundpreis")
            }
        })
        const testNetto = computed(() => {
            if (laedt.value === false) {
                return store.state.testphaseRP.find(k => k.art === "netto")
            }
        })
        const testMwst = computed(() => {
            if (laedt.value === false) {
                return store.state.testphaseRP.find(k => k.art === "mwst")
            }
        })
        const testBrutto = computed(() => {
            if (laedt.value === false) {
                return store.state.testphaseRP.find(k => k.art === "brutto")
            }
        })

        return {
            jaehrlich,
            laedt,
            testGp,
            testNetto,
            testMwst,
            testBrutto,
            positionZuWertString
        }
    }
}
</script>
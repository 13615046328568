const testGrossKleinschreibung = (val) =>
  typeof val === "string" && /^(?=.*[a-z])(?=.*[A-Z]).*$/.test(val);

const testSonderzeichen = (val) =>
  typeof val === "string" &&
  /[\!\@\#\$\%\^\&\*\(\)\-\_\=\+\[\]\{\}\\\|\;\:\'\"\<\,\>\.\?\/\~\`]/.test(
    val
  );

const testLaenge = (val) => typeof val === "string" && /^.{8,30}$/.test(val);

const testZiffer = (val) => typeof val === "string" && /\d/.test(val);

const testAdressenBestandteil = (val) =>
  typeof val === "string" &&
  /^(?!^\s+$)(?=.*[A-Za-z0-9äöüÄÖÜß\s\+\-\/,]).*$/.test(val);

const testNurZiffern = (val) =>
  typeof val === "string" && /^(?=.*\d)\d{1,10}$/.test(val);

export {
  testGrossKleinschreibung,
  testSonderzeichen,
  testLaenge,
  testZiffer,
  testAdressenBestandteil,
  testNurZiffern,
};

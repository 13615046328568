<template>
    <!-- Karte -->
    <button v-if="mobile"
        class="w-full flex flex-col justify-start items-start py-6 px-5 space-y-3 rounded-md bg-TextField">
        <!-- Header -->
        <div class="flex flex-col justify-start items-start space-y-3 ">
            <p class=" text-2xl font-bold">{{ paket.name }}</p>
            <div class="w-1/6 h-0.5 rounded-full" :class="[akzent]"></div>
            <p class="text-sm font-light">{{ paket.beschreibung }}</p>
        </div>

        <!-- Kapazitäten -->
        <div class="w-full flex flex-col text-xs space-y-0.5">
            <!-- Speicher -->
            <div class="w-full flex justify-center items-start text-MittelGrau">
                <div class="w-7 flex justify-end mr-3" :class="akzentText">{{ speicher }}</div>
                <div class="w-full flex justify-start font-light">GB Cloud Speicher</div>
            </div>

            <!-- Nutzer -->
            <div class="w-full flex justify-center items-center text-MittelGrau">
                <div class="w-7 flex justify-end mr-3" :class="akzentText">{{ paket.nutzer }}</div>
                <div class="w-full flex justify-start font-light">Benutzer</div>
            </div>

            <!-- Geräte -->
            <div class="w-full flex justify-center items-center text-MittelGrau">
                <div class="w-7 flex justify-end mr-3" :class="akzentText">{{ paket.geraete }}</div>
                <div class="w-full flex justify-start font-light">Geräte</div>
            </div>
        </div>

        <div class="w-full h-[1px] bg-LeichtesGrau bg-opacity-50"></div>

        <!-- Feste Features -->
        <div class="w-full flex justify-between text-xs text-MittelGrau">
            <!-- iOS App -->
            <div class="flex justify-center items-center space-x-2">
                <img :src="haken" class="w-3 aspect-square">
                <p class="flex justify-start font-light">iOS App</p>
            </div>

            <!-- Web App -->
            <div class="flex justify-center items-center space-x-2">
                <img :src="haken" class="w-3 aspect-square">
                <p class="flex justify-start font-light">Web App</p>
            </div>

            <!-- DSGVO Vorlagen -->
            <div class="flex justify-center items-center space-x-2">
                <img :src="haken" class="w-3 aspect-square">
                <p class="flex justify-start font-light">DSGVO Vorlagen</p>
            </div>
        </div>

        <div class="w-full flex justify-center space-x-0.5 text-sm text-DentaBlue pt-3">
            <p>Paket Wechseln</p>
            <img src="@/assets/icons/pfeil-rechts-blau.png" alt=">" class="h-5">
        </div>
    </button>
    <button v-else
        class="bg-TextField transition duration-100 hover:bg-[#eaeaea] w-80 aspect-paketkarte rounded-3xl px-8 py-10 flex flex-col justify-between space-y-10"
        :class="aktivHighlight">
        <!-- Header -->
        <div class="flex flex-col justify-start items-start space-y-2">
            <p class=" text-3xl font-bold">{{ paket.name }}</p>
            <div class="w-1/6 h-0.5 rounded-full" :class="[akzent]"></div>
            <p class="text-sm font-light">{{ paket.beschreibung }}</p>
        </div>

        <!-- Informationen -->
        <div class="w-full flex flex-col">
            <!-- Speicher -->
            <div class="w-full flex justify-center items-center text-MittelGrau">
                <div class="w-1/4 flex justify-end mr-3" :class="akzentText">{{ speicher }}</div>
                <div class="w-3/4 flex justify-start font-light">GB Cloud Speicher</div>
            </div>

            <!-- Nutzer -->
            <div class="w-full flex justify-center items-center text-MittelGrau">
                <div class="w-1/4 flex justify-end mr-3" :class="akzentText">{{ paket.nutzer }}</div>
                <div class="w-3/4 flex justify-start font-light">Individuelle Nutzer</div>
            </div>

            <!-- iOS App -->
            <div class="w-full flex justify-center items-center text-MittelGrau">
                <div class="w-1/4 flex justify-end mr-3"><img :src="haken" class="w-3 aspect-square"></div>
                <div class="w-3/4 flex justify-start font-light">iOS App</div>
            </div>

            <!-- Web App -->
            <div class="w-full flex justify-center items-center text-MittelGrau">
                <div class="w-1/4 flex justify-end mr-3"><img :src="haken" class="w-3 aspect-square"></div>
                <div class="w-3/4 flex justify-start font-light">Web App</div>
            </div>

            <!-- DSGVO Vorlagen -->
            <div class="w-full flex justify-center items-center text-MittelGrau">
                <div class="w-1/4 flex justify-end mr-3"><img :src="haken" class="w-3 aspect-square"></div>
                <div class="w-3/4 flex justify-start font-light">DSGVO Vorlagen</div>
            </div>
        </div>

        <!-- Preis -->
        <p class="w-full text-center text-DentaBlue">Paket Wechseln</p>
    </button>
</template>
<script>
import { computed } from 'vue';
import { preisStringGenerieren } from '@/dienste/helfer';
import store from '@/store';
export default {
    emits: ["gewaehlt"],
    props: ["mobile"],
    setup(props, context) {

        const paket = computed(() => store.state.paket)

        /* Klasse wird für das einfärben eines Element-Hintergrunds mit der regulären Akzentfarbe verwendet */
        const akzent = computed(() => {
            if (paket.value) {
                switch (paket.value.rang) {
                    case 0: return "bg-[#72D7DD]"
                    case 1: return "bg-[#007AFF]"
                    default: return "bg-[#C05BE3]"
                }
            }
        })

        /* Klasse wird für das einfärben eines Element-Hintergrunds mit der Hintergrundversion der Akzentfarbe verwendet */
        const akzentHintergrund = computed(() => {
            if (paket.value) {
                switch (paket.value.rang) {
                    case 0: return "bg-[#EEFAFB]"
                    case 1: return "bg-[#DDECFF]"
                    default: return "bg-[#F7EAFB]"
                }
            }
        })

        /* Klasse wird für das einfärben eines Element-Textes mit der regulären Akzentfarbe verwendet */
        const akzentText = computed(() => {
            if (paket.value) {
                switch (paket.value.rang) {
                    case 0: return "text-[#72D7DD]"
                    case 1: return "text-[#007AFF]"
                    default: return "text-[#C05BE3]"
                }
            }
        })

        const speicher = computed(() => {
            if (paket.value) {
                return paket.value.kapazitaet
            }
        })

        const haken = computed(() => {
            if (paket.value) {
                switch (paket.value.rang) {
                    case 0: return require("@/assets/icons/haken-gruen.svg")
                    case 1: return require("@/assets/icons/haken-blau.svg")
                    default: return require("@/assets/icons/haken-violett.svg")
                }
            }
        })

        return {
            paket,
            akzent,
            akzentHintergrund,
            akzentText,
            speicher,
            haken,
            preisStringGenerieren,
        }
    }
}
</script>
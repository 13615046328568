<template>
  <div class="w-full flex flex-col justify-start items-start font-dm-sans">
    <ZurueckButton v-if="hatZurueckButton === true" @click="zurueckGeklickt" />
    <p class="mt-4 mb-2 text-4xl font-semibold text-start">{{ titel }}</p>
    <p class="text-MittelGrau font-thin text-start">{{ untertitel }}</p>
  </div>
</template>

<script>
import ZurueckButton from './ZurueckButton.vue';
export default {
  components: {
    ZurueckButton
  },
  props: ["hatZurueckButton", "titel", "untertitel"],
  emits: ["zurueck"],
  setup(_, context) {
    const zurueckGeklickt = () => context.emit("zurueck")
    return { zurueckGeklickt }
  }
};
</script>
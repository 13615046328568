<template>
    <div :class="{ 'onboarding-komponente': mobile === false, 'onboarding-komponente-mobile': mobile === true }">
        <Titel v-if="!mobile" titel="AGB & Datenschutz"
            :untertitel="'Bitte lesen und bestätigen Sie unsere AGB und Datenschutzbedingungen.'" />

        <!-- AGB und Angebote Checkboxen -->
        <div class="w-full h-full flex flex-col space-y-6" :class="{ 'text-sm': mobile === true }">
            <!-- AGB -->
            <div class="w-full flex justify-start items-center space-x-5">
                <Checkbox :wert="agb" @click="agbSetzen" />
                <span class="w-4/5">Ich habe die <a href="https://dentasnap.de/rechtliches/dokumente"
                        target="_blank">AGB</a> und die <a href="https://dentasnap.de/rechtliches/dokumente"
                        target="_blank">Datenschutzbedingungen</a> gelesen
                    und stimme Ihnen zu.</span>
            </div>

            <!-- Angebote -->
            <div class="w-full flex justify-start items-center space-x-5">
                <Checkbox :wert="angebote" @click="angeboteSetzen" />
                <span class="w-4/5">Ja, ich würde gerne Angebote und updates zu Dentasnap und weiteren Produkten der JMS
                    Invent GmbH
                    erhalten.</span>
            </div>
        </div>

        <!-- <button @click="testDatenSetzen">Test Daten Setzen</button> -->

        <WeiterButton :mobile="mobile" :deaktiviert="!agb" @klick="weiter" />
    </div>
</template>
<script>
import Titel from "@/components/Titel.vue"
import Checkbox from "@/components/Checkbox/Checkbox.vue"
import WeiterButton from "@/components/WeiterButton.vue"
import { computed } from "vue"
import store from "@/store"
export default {
    components: { Titel, Checkbox, WeiterButton },
    props: ["mobile"],
    emits: ["testDatenSetzen", "weiter"],
    setup(_, context) {

        const testDatenSetzen = () => context.emit("testDatenSetzen")

        const weiter = () => context.emit("weiter")

        const agb = computed(() => store.state.agb)
        const angebote = computed(() => store.state.angebote)

        const agbSetzen = () => store.commit("setAgb", !agb.value)
        const angeboteSetzen = () => store.commit("setAngebote", !angebote.value)

        return { agb, angebote, agbSetzen, angeboteSetzen, testDatenSetzen, weiter }
    }
}
</script>
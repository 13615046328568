<template>
    <button v-if="mobile === true"
        class="w-full h-14 rounded-full flex justify-center items-center text-white font-dm-sans text-lg" :class="{
            'bg-DentaBlue': !deaktiviert,
            'bg-LeichtesGrau pointer-events-none': deaktiviert === true,
        }" @click="klick">
        <div v-if="!laedt" class="h-14 flex space-x-2 justify-center items-center">
            <p>{{ typeof titel === "string" ? titel : "Weiter" }}</p>
            <img src="@/assets/icons/arrow-forward.svg" alt="->" class="w-6">
        </div>
        <LottieAnimation v-else class="h-14" :animation-data="ladenWeiss" :loop="true" />
    </button>
    <div v-else class="w-full h-12 flex justify-start">
        <button :disabled="deaktiviert || laedt" :style="titel != null ? `width: ${width}` : ''"
            class="h-full rounded-full text-white" :class="{
                'transition duration-100 hover:bg-opacity-80': !deaktiviert && !laedt,
                'bg-DentaBlue': deaktiviert === false,
                'bg-LeichtesGrau': deaktiviert === true,
                'w-12': titel == null,
                'px-6': titel != null
            }" @click="klick">
            <LottieAnimation v-if="laedt === true" class="h-full" :animation-data="ladenWeiss" :loop="true" />
            <div v-else class="flex justify-center items-center space-x-4">
                <p v-if="titel != null">{{ titel }}</p>
                <img src="@/assets/icons/arrow-forward.svg" alt="->" class="h-6">
            </div>
        </button>
    </div>
</template>

<script>
import ladenWeiss from "@/assets/animations/laden-weiss.json"
import { LottieAnimation } from "lottie-web-vue";
export default {
    components: { LottieAnimation },
    props: ["mobile", "width", "titel", "laedt", "deaktiviert"],
    emits: ["klick"],
    setup(_, context) {
        const klick = () => context.emit("klick")
        return {
            klick,
            ladenWeiss
        }
    }
}
</script>
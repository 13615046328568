import { firestore } from "@/firebase/config";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { versionAbfragen } from "./helfer";
import { fehlerSenden } from "./LogDienst";

/**
 * Fragt das Dokument zum Paket ab, und gibt es zurück.
 * Wenn kein passendes Dokument gefunden wird, wird "null" zurückgegeben.
 * @param {*} paketId ID des Pakets
 * @returns {Promise<import("firebase/firestore").DocumentData | null>}
 */
async function paketAbfragen(paketId) {
  try {
    let docSnap = await getDoc(doc(firestore, "pakete", paketId));

    if (docSnap.exists()) {
      return docSnap.data();
    }
  } catch (error) {
    fehlerSenden(
      error,
      "Fehler beim Abfragen eines Pakets.",
      "paketAbfragen",
      "firestore"
    );
  }
}

/**
 * Gibt die Liste aller verfügbarer (öffentlichen) Pakete zurück
 * @returns {{id: string, name: string, rechnungsName: string, monatsNettoPreis: number, jahresNettoPreis: number, rang: number}[]}
 */
async function paketeAbfragen() {
  try {
    const docs = await getDocs(collection(firestore, "pakete"));
    return docs.docs.map((d) => d.data()).sort((a, b) => a.rang - b.rang);
  } catch (error) {
    fehlerSenden(
      error,
      "Fehler beim Abfragen der Paketliste",
      "paketeAbfragen",
      "firestore.js"
    );
  }
}

/**
 * Prüft:
 * - Ob der Wartungszustand für die Registrierung aktiv ist
 * - Ob der Nutzer eine veraltete Clientversion verwendet
 * @returns {number}
 * - 0: Wenn keine Instandhaltungsausnahme vorliegt
 * - 91: Wenn der Wartungsmodus für die Registrierung aktiv ist
 * - 92: Wenn die Clientversion veraltet ist
 */
async function instandhaltungPruefen() {
  try {
    const version = versionAbfragen().split("-");

    const name = version[0];
    const nummer = version[1];

    const versionenDaten = (
      await getDoc(doc(firestore, "instandhaltung/versionen"))
    ).data();
    const wartungsDaten = (
      await getDoc(doc(firestore, "instandhaltung/wartung"))
    ).data();

    if (wartungsDaten[name] == undefined || wartungsDaten[name] == true) {
      store.commit("wartungSetzen", true);
      return 91;
    } else {
      store.commit("wartungSetzen", false);
    }

    if (versionenDaten[name].find((v) => v == nummer) == undefined) {
      store.commit("updateNoetigSetzen", true);
      return 92;
    } else {
      store.commit("updateNoetigSetzen", false);
    }

    return 0;
  } catch (error) {
    fehlerSenden(
      error,
      "Fehler bei der Instandhaltungsprüfung.",
      "instandhaltungPruefen",
      "firestore"
    );
  }
}

export { paketAbfragen, paketeAbfragen, instandhaltungPruefen };

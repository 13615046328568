<template>
    <NaechsteSchritteView :mobile="mobile" :bearbeitung="laedt" />
</template>
<script>
import { onMounted, ref } from 'vue';
import NaechsteSchritteView from './components/NaechsteSchritteView.vue';
import store from '@/store';
import { signInWithCustomToken } from 'firebase/auth';
import { auth } from '@/firebase/config';
export default {
    components: {
        NaechsteSchritteView
    },
    props: ["mobile"],
    setup(props, context) {
        const laedt = ref(false)

        async function laborRegistrierungVerarbeiten() {
            if (typeof store.state.anmeldeToken === "string") {
                laedt.value = true
                await signInWithCustomToken(auth, store.state.anmeldeToken)
                laedt.value = false
            }
        }

        onMounted(laborRegistrierungVerarbeiten)

        return {
            laedt
        }
    }
}
</script>
<template>
    <div class="w-full h-20 flex flex-col space-y-2 justify-start items-start">
        <!-- Eingabefeld -->
        <div class="w-full h-full flex space-x-3">
            <input type="text" ref="textRef" name="couponCode" id="couponCode" v-model="text"
                class="w-full h-12 bg-TextField px-5 rounded-lg outline-none" placeholder="Gutscheincode"
                @keyup.enter.prevent="codePruefen">
            <button class="w-32 h-12 rounded-lg flex justify-center items-center" :disabled="laedt === true" :class="{
                ' bg-LeichtesGruen': gueltig === true,
                'bg-LeichtesRot transition duration-100 hover:bg-[#FFCECE]': gueltig === false,
                ' bg-LeichtesBlau transition duration-100 hover:bg-[#c2dffd]': gueltig == null
            }" @click="buttonClick">
                <LottieAnimation v-if="laedt === true" :animation-data="ladenBlau" :loop="true" class="w-16" />
                <img v-else-if="gueltig === true" src="@/assets/icons/haken-zwei-gruen.png" alt="haken" class="w-6">
                <img v-else-if="gueltig === false" src="@/assets/icons/schliessen-rot.png" alt="schliessen" class="w-6">
                <img v-else-if="gueltig == null" src="@/assets/icons/pfeil-rechts-blau.png" alt="pfeil" class="w-6">
            </button>
        </div>

        <!-- Fehlermeldung -->
        <span v-html="unterTitelHtml" :class="{ 'text-Warning': gueltig === false }" class="text-xs px-2"></span>
    </div>
</template>
<script>
import { computed, ref, watch, watchEffect } from 'vue';
import { LottieAnimation } from 'lottie-web-vue';
import { funktionAufrufen } from '@/dienste/functions';
import { fehlerSenden } from '@/dienste/LogDienst';
import store from '@/store';
const ladenBlau = require("@/assets/animations/laden-blau.json")
export default {
    components: { LottieAnimation },
    //TODO: - Logik implementieren, die einen eingegebenen Code prüft
    //TODO: - Logik implementieren, die beim Laden den Inhalt des feldes ausgraut und einen Ladeindikator anzeigt
    //TODO: - Im Erfolgsfall ein Event mit Code name und faktor zurückgeben
    //TODO: - In allen Fällen das Feld entsprechend visuell anpassen
    setup(props, context) {
        /** Aktuell eingegebener Text */
        const text = ref(store.state.preisMinderung != null ? store.state.preisMinderung.code : null)
        const textRef = ref(null)

        const pm = computed(() => store.state.preisMinderung)
        const paket = computed(() => store.state.paket)
        const frist = computed(() => store.state.frist)

        const aktuellePaketId = ref(store.state.paket.id)
        const aktuelleFrist = ref(store.state.frist)

        watch(text, val => {
            if (val == "") {
                zuruecksetzen()
            }
        })

        watchEffect(() => {
            if (store.state.paket && store.state.paket.id !== aktuellePaketId.value && store.state.frist && store.state.frist !== aktuelleFrist.val && text.value && text.value != "") {
                aktuellePaketId.value = store.state.paket.id
                aktuelleFrist.value = store.state.frist
                codePruefen()
                console.log("Preisminderung wird automatisch geprüft");
            }
        })

        /** Ob der aktuelle Code gültig (true), ungültig (false) oder noch nicht geprüft (null) ist. */
        const gueltig = ref(null)

        const laedt = ref(false)

        const unterTitelHtml = computed(() => {
            if (gueltig.value === false) {
                return `Der angegebene Code ist ungültig.`
            } else if (gueltig.value === true && pm.value != null) {
                if (typeof pm.value.dauer === "number") {
                    //Wir rechnen die "dauer" * 12, da alle Rabattcodes für x volle Jahre anwendbar sind
                    return `Dieser Code reduziert Ihren Preis für ${pm.value.dauer * 12} Monate. Nach Ablauf erhöht sich der Betrag auf den Standardpreis. <a href='https://dentasnap.de/rechtliches/dokumente' target='_blank'>Es gelten unsere AGB.</a>`
                } else {
                    return "<a href='https://dentasnap.de/rechtliches/dokumente' target='_blank'>Es gelten unsere AGB.</a>"
                }
            } else {
                return ""
            }
        })

        function codePruefen() {
            if (text.value && !laedt.value && !text.value.empty) {
                laedt.value = true
                funktionAufrufen("preisMinderungPruefen", {
                    code: text.value,
                    paket: paket.value.id,
                    frist: frist.value
                })
                    .catch(err => fehlerSenden(err, "Fehler beim Aufrufen der Cloudfunktion zum Prüfen einer Preisminderung", "codePruefen", "PreisminderungEingabe"))
                    .then(res => {
                        if (res.code == 0) {
                            gueltig.value = true
                            textRef.value.blur()
                            store.commit("setPreisMinderung", res.daten)
                        }
                        else {
                            gueltig.value = false
                        }
                    })
                    .finally(() => laedt.value = false)
            }
        }

        function zuruecksetzen() {
            if (text.value) {
                textRef.value.focus()
            }
            text.value = null
            gueltig.value = null
            laedt.value = false
            store.commit("setPreisMinderung", null)
        }

        function buttonClick() {
            if (gueltig.value == null && text.value && !text.value.empty) {
                //Neuen Code eingegeben
                codePruefen()
            } else {
                zuruecksetzen()
            }
        }

        return { text, textRef, gueltig, laedt, ladenBlau, unterTitelHtml, codePruefen, buttonClick }
    }

}
</script>
<template>
    <div class="w-full px-10 py-6 rounded-2xl bg-TextField flex flex-col space-y-3 items-start">
        <p class="w-full text-sm text-MittelGrau">Zahlungsmethode</p>
        <div class="flex space-x-4 justify-center items-center">
            <!-- Icon -->
            <img :src="logo" :alt="name" class="w-10 aspect-auto object-contain">

            <!-- Name -->
            <p class="text-xl tracking-wide">{{ name }}</p>
        </div>
        <div class="w-full flex flex-col space-y-2 pt-3 justify-start items-start">
            <p class="text-sm font-bold">Wichtiger Hinweis:</p>
            <span class="text-xs" v-html="abrechnungsHinweis"></span>
        </div>

        <div v-if="zm == 'giropay'" class="w-full flex flex-col space-y-2 pt-3 justify-start items-start">
            <p class="text-sm font-bold">SEPA-Lastschriftmandat:</p>
            <span class="text-xs">Ich ermächtige die JMS Invent GmbH, Zahlungen von meinem Konto mittels Lastschrift
                einzuziehen. Zugleich weise ich mein Kreditinstitut an, die von der JMS Invent GmbH auf mein Konto gezogenen
                Lastschriften einzulösen.<br /><br />Hinweis: Ich kann innerhalb von acht Wochen, beginnend mit dem
                Belastungsdatum, die Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit meinem
                Kreditinstitut vereinbarten Bedingungen.</span>
        </div>
    </div>
</template>
<script>
import { computed } from 'vue';
import { allgemeinenAbrechnungsHinweisGenerieren } from '@/dienste/helfer';
import store from '@/store';
export default {
    setup(props) {
        const zm = computed(() => store.state.zahlungsMethode)

        const name = computed(() => {
            switch (store.state.zahlungsMethode) {
                case "creditcard": return "Kreditkarte"
                case "giropay": return "GiroPay"
                case "paypal": return "PayPal"
                default: return ""
            }
        })

        const logo = computed(() => {
            switch (store.state.zahlungsMethode) {
                case "creditcard": return require("@/assets/pm-logos/kreditkarten.png")
                case "giropay": return require("@/assets/pm-logos/giropay.png")
                case "paypal": return require("@/assets/pm-logos/paypal.png")
                default: return ""
            }
        })

        const abrechnungsHinweis = computed(() => allgemeinenAbrechnungsHinweisGenerieren(store.state.frist))

        return {
            zm,
            name,
            logo,
            abrechnungsHinweis
        }
    }
}
</script>
<template>
    <form @submit="weiter" @keydown.enter.prevent=""
        :class="{ 'onboarding-komponente': mobile === false, 'onboarding-komponente-mobile': mobile === true }">
        <Titel v-if="!mobile" titel="Ihr Konto"
            untertitel="Richten Sie zunächst Ihr DentaSnap Konto ein. Das Konto wird Eigentümer Ihrer neuen DentaSnap Organisation." />
        <div class="flex flex-col justify-start items-start space-y-8"
            :class="{ 'w-full h-full': mobile, 'w-96': !mobile }">
            <div class="flex flex-col space-y-2">
                <!-- Anrede + Name -->
                <div class="w-full flex space-x-2">
                    <div class="w-4/5 rounded-md bg-TextField relative">
                        <select class="absolute w-full h-full px-2 py-3 focus:outline-none opacity-0" v-model="anrede">
                            <option v-for="a in anreden" :key="a" :value="a">{{ a }}</option>
                        </select>
                        <p
                            class="absolute z-10 w-full h-full px-5 py-3 flex justify-start items-center pointer-events-none">
                            {{
                                anrede }}</p>
                    </div>
                    <input type="text" name="first-name" autocomplete="given-name" class="w-full textfeld-adresse"
                        placeholder="Vorname" v-model="vorname">
                    <input type="text" name="last-name" autocomplete="family-name" class="w-full textfeld-adresse"
                        placeholder="Nachname" v-model="nachname">
                </div>
                <input type="email" name="email" autocomplete="email" class="textfeld" placeholder="E-Mail Adresse"
                    v-model="email">
            </div>
            <div class="w-full flex flex-col space-y-2">
                <input type="password" name="password" autocomplete="new-password" class="textfeld"
                    placeholder="Passwort" v-model="pwt">
                <input type="password" name="password" autocomplete="new-password" class="textfeld"
                    placeholder="Passwort Wiederholen" v-model="pwtWdh">
            </div>
            <div class="w-full flex flex-wrap items-start"
                :class="{ 'justify-center': mobile === true, 'justify-start': mobile === false }">
                <PasswortVorraussetzung :vorraussetzung="grossUndKleinschreibung" text="Groß -und Kleinschreibung" />
                <PasswortVorraussetzung :vorraussetzung="sonderzeichen" text="Ein Sonderzeichen" />
                <PasswortVorraussetzung :vorraussetzung="ziffer" text="Eine Ziffer" />
                <PasswortVorraussetzung :vorraussetzung="korrekteLaenge" text="Zwischen 8 und 30 Zeichen" />
            </div>
        </div>
        <WeiterButton id="submit" :mobile="mobile" :deaktiviert="!gueltigeEingabe" @klick="weiter" />
    </form>
</template>
<script>
import Titel from '@/components/Titel.vue';
import WeiterButton from '@/components/WeiterButton.vue';
import PasswortVorraussetzung from '@/components/PasswortVorraussetzung.vue';
import { anreden } from '@/constants';
import { testGrossKleinschreibung, testLaenge, testSonderzeichen, testZiffer } from "@/constants/regex"
import { computed, onMounted, ref, watch } from 'vue';
import store from '@/store';
export default {
    components: {
        Titel,
        WeiterButton,
        PasswortVorraussetzung
    },
    props: ["mobile"],
    emits: ["weiter"],
    setup(props, context) {

        const istLabor = computed(() => store.state.istViewer)

        const anrede = ref(anreden[0])
        const vorname = ref("")
        const nachname = ref("")

        const displayName = computed(() => {
            if (anrede.value != null && vorname.value != "" && nachname.value != "") {
                return `${anrede.value}-${vorname.value}-${nachname.value}`
            }
        })

        const email = ref(store.state.emailAdresse ?? "")
        const pwt = ref(store.state.passwort ?? "")
        const pwtWdh = ref(store.state.passwort ?? "")

        const emailGueltig = computed(() => typeof store.state.emailAdresse === "string" && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(store.state.emailAdresse))

        //Vorraussetzungen und Gültigkeitscheck für Passwort
        const grossUndKleinschreibung = computed(() => testGrossKleinschreibung(pwt.value))
        const sonderzeichen = computed(() => testSonderzeichen(pwt.value))
        const korrekteLaenge = computed(() => testLaenge(pwt.value))
        const ziffer = computed(() => testZiffer(pwt.value))
        const eingabenGleich = computed(() => pwt.value === pwtWdh.value)

        const gueltigeEingabe = computed(() => emailGueltig.value &&
            grossUndKleinschreibung.value &&
            sonderzeichen.value &&
            ziffer.value &&
            korrekteLaenge.value &&
            eingabenGleich.value)

        watch(email, val => store.commit("setEmailAdresse", val))
        watch(pwt, val => store.commit("setPasswort", val))
        watch(displayName, val => {
            //Display Name ist nur vorhanden, wenn alle Namensbestandteiu
            if (typeof val === "string") {
                store.commit("setDisplayName", val)
            }
        })

        //Falls bereits ein Name gesetzt wurde, werden die Eingabefelder hier initalisiert
        onMounted(() => {
            if (typeof store.state.displayName === "string") {
                const split = store.state.displayName.split("-")
                anrede.value = split[0]
                vorname.value = split[1]
                nachname.value = split[2]
            }
        })

        const weiter = () => context.emit("weiter")

        return {
            anreden,
            anrede,
            vorname,
            nachname,
            email,
            pwt,
            pwtWdh,
            istLabor,
            grossUndKleinschreibung,
            sonderzeichen,
            ziffer,
            korrekteLaenge,
            eingabenGleich,
            gueltigeEingabe,
            weiter
        }
    }
}
</script>
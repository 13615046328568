<template>
  <div class="w-8 h-8 cursor-pointer">
    <Transition name="fade" mode="out-in">
      <img v-if="wert" src="@/assets/icons/checkbox-checked.png" alt="->" class="object-cover">
      <img v-else src="@/assets/icons/checkbox-empty.png" alt="->" class="object-cover">
    </Transition>
  </div>
</template>

<script>
//Alle eigenen Komponenten werden importiert
import { computed } from "@vue/runtime-core";

export default {
  props: ["wert"],
  setup(props, _) {
    const checked = computed(() => props.wert);

    const widgetFuerZustand = computed(() => {
      switch (checked.value) {
        case true:
          return CheckboxChecked;
        default:
          return CheckboxEmpty;
      }
    });

    return {
      //State für das UI
      checked,
      widgetFuerZustand,
    };
  },
};
</script>

<!-- Styles needed for transition animation -->
<style scoped>
.fade-enter-active {
  transition: all 0.1s ease-out;
}

.fade-leave-active {
  transition: all 0.1s ease-in;
}

.fade-enter-from {
  opacity: 0.5;
}

.fade-enter-to {
  opacity: 1;
}

.fade-leave-from {
  opacity: 1;
}

.fade-leave-to {
  opacity: 0.5;
}
</style>
<template>
    <GDialog :fullscreen="true" v-model="paketWechselnState">
        <PaketWechseln :mobile="mobile" @schliessen="paketWechselnState = false" />
    </GDialog>

    <!-- <div v-if="mobile" class="onboarding-komponente-mobile">
        <AktuellesPaket :mobile="mobile" @click="paketWechselnState = true" />

        <ZahlungsMethodenWahl :mobile="mobile" />
    </div> -->
    <div
        :class="{ 'flex justify-start items-center font-dm-sans space-x-24': mobile === false, 'onboarding-komponente-mobile': mobile === true }">
        <form @submit.prevent class="h-full flex flex-col space-y-8"
            :class="{ 'w-[26rem]': mobile === false, 'w-full': mobile === true }">
            <Titel v-if="!mobile" titel="Zahlungsmethode" untertitel="Wählen Sie wann und wie Sie zahlen möchten." />

            <AktuellesPaket v-if="mobile" :mobile="mobile" @click="paketWechselnState = true" />

            <ZahlungsMethodenWahl :mobile="mobile" />

            <!-- Rabattcodeeingabe -->
            <PreisminderungEingabe />

            <!-- Zusammenfassung -->
            <KostenVorschau />

            <div class="w-full flex" :class="{ 'pb-10': mobile === true }">
                <WeiterButton :mobile="mobile" :deaktiviert="laedt || typeof zahlungsMethode !== 'string'"
                    @klick="weiter" />
            </div>
        </form>

        <!-- Paket Karte und Wechselbutton -->
        <AktuellesPaket v-if="!mobile" :mobile="mobile" @click="paketWechselnState = true" />
    </div>
</template>

<script>
import Titel from '@/components/Titel.vue';
import WeiterButton from '@/components/WeiterButton.vue';
import ZahlungsMethodenWahl from './components/zahlungs-methoden-wahl/ZahlungsMethodenWahl.vue';
import PreisminderungEingabe from './components/PreisminderungEingabe.vue';
import AktuellesPaket from './components/AktuellesPaket.vue';
import PaketWechseln from './pages/paket-wechseln/PaketWechseln.vue';
import KostenVorschau from './components/KostenVorschau.vue';
import { computed, ref, watch, watchEffect } from 'vue';
import { zahlungsMethoden } from '@/constants';
import { GDialog } from 'gitart-vue-dialog';
import store from '@/store';

export default {
    components: {
        Titel,
        WeiterButton,
        ZahlungsMethodenWahl,
        PreisminderungEingabe,
        GDialog,
        AktuellesPaket,
        PaketWechseln,
        KostenVorschau,
    },
    props: ["mobile"],
    emits: ["weiter"],
    setup(props, context) {
        /** State für den "Paket Wechseln" Dialog */
        const paketWechselnState = ref(false)

        /** Wenn der Nutzer in neues Paket gewählt hat, wird das UI zur Paketwahl geschlosse */
        watchEffect(() => {
            if (store.state.paket != null) {
                paketWechselnState.value = false
            }
        })

        const pmGeprueft = (pm) => {
            if (pm != null) {
                preisminderung.value = pm
            }
            pmPruefen.value = false
        }

        const laedt = ref(false)

        const preisminderung = computed(() => store.state.preisMinderung)
        const zahlungsMethode = computed(() => store.state.zahlungsMethode)
        const aktuellesPaket = computed(() => store.state.paket)

        const setZahlungsMethode = (zm) => store.commit("setZahlungsMethode", zm)
        const setPreisMinderung = (pm) => store.commit("setPreisMinderung", pm)

        watch(zahlungsMethode, val => store.commit("setZahlungsMethode", val))
        watch(preisminderung, val => store.commit("setPreisMinderung", val))

        /** Wenn der Nutzer seine Abooptionen ändert, wird dieser wert gewechselt um eventuelle Preisminderungen neu zu prüfen. */
        const pmPruefen = ref(false)

        const weiter = () => context.emit("weiter")

        return {
            paketWechselnState,
            zahlungsMethoden,
            zahlungsMethode,
            laedt,
            pmPruefen,
            preisminderung,
            aktuellesPaket,
            setZahlungsMethode,
            setPreisMinderung,
            pmGeprueft,
            weiter,
        }
    }
}
</script>